.header-title {
    color: #15033E !important;
}

.page-wrapper {
    margin-top: 150px !important;
    width: 100vw;
}
.full_wrapper{
    width:100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-para-div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
}

.header-para {
    width: 60%
}

.banner_image_section{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding: 70px;

}

.banner_image {
    width: 60%;
    height: 60%;
    margin-top: 40px;
    margin-bottom: 40px;
}
.slider_section{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #F6F7FB;
    padding: 70px;
}

.explanation_section{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 70px;
    /* background-color: #F6F7FB; */
}
.test_section{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 70px;
    background-color: #F6F7FB;
}
.accordian_body{
    width: 80%;
    margin-top: 30px;
}
.test_web_body{
    width: 80%;
    margin-top: 30px;
}
.border_line_purple{
    width:8px;
    height:120px;
    border:5px solid #5C2CC5
}
.border_line_red{
    width:8px;
    height:120px;
    border:5px solid #F23460
}
.border_text_body{
    margin: 10px 0px 0px 20px;
}
.border_text_para{
    margin-top: 10px;
    font-size: 16px;
    line-height: 30px;
}
.border_text_title_purple{
    color:#5C2CC5;
}
.border_text_title_red{
    color:#F23460;
}
.testing_stage{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 70px;
    background-color: #F6F7FB;
}
.stage_box_single{
    border: 2px dotted #F23460;
    width: 100%;
    padding: 30px;
}
.badge_box{
    width:100%
}

.card_content{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1000px) {
    .banner_image {
        width: 100%;
        height: 1000%;
        margin-top: 40px;
        margin-bottom: 40px;
    }
}