/* reset css start */
/* font family */
@font-face{
    font-family: "now";
    src: url(../fonts/Now-Thin.otf);
    font-weight: 100;
}
@font-face{
    font-family: "now";
    src: url(../fonts/Now-Light.otf);
    font-weight: 300;
}
@font-face{
    font-family: "now";
    src: url(../fonts/Now-Regular.otf);
    font-weight: 400;
}
@font-face{
    font-family: "now";
    src: url(../fonts/Now-Medium.otf);
    font-weight: 500;
}
@font-face{
    font-family: "now";
    src: url(../fonts/Now-Bold.otf);
    font-weight: 800;
}
@font-face{
    font-family: "GlacialIndifference";
    src: url(../fonts/GlacialIndifference-Bold.otf);
    font-weight: 800;
}
@font-face{
    font-family: "GlacialIndifference";
    src: url(../fonts/GlacialIndifference-Regular.otf);
    font-weight: 400;
}
:root{
    --body-font: 'now';
    --body-alt-font: 'GlacialIndifference';
    --body-color: rgb(255, 255, 255);
}
html{
    scroll-behavior: smooth;
    overflow-x: hidden;
}
body {
    font-size: 18px;
    font-family: var(--body-font);
    padding: 0;
    margin: 0;
    font-weight: 400;
    /* box-sizing: border-box !important; */
    /* overflow-x: hidden !important; */
}
h1,h2,h3,h4,h5,h6 {
    padding: 0;
    margin: 0;
  }
  h1,h1>a,
  h2,h2>a,
  h3,h3>a,
  h4,h4>a,
  h5,h5>a,
  h6,h6>a {
    font-family: var(--body-font);
    line-height: 1.25;
    font-weight: 600;
    text-transform: initial;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
  }
  h1>a:hover,
  h2>a:hover,
  h3>a:hover,
  h4>a:hover,
  h5>a:hover,
  h6>a:hover {
    color: rgb(90, 62, 142);
  }
  h1, h1>a {
    font-size: 36px;
    line-height: 46px;
  }
  h2, h2>a {
    font-size: 28px;
    line-height: 38px;
    text-decoration: none;
  }
  h3, h3>a {
    font-size: 24px;
    line-height: 34px;
  }
  h4, h4>a {
    font-size: 20px;
    line-height: 30px;
  }
  h5, h5>a {
    font-size: 18px;
    line-height: 28px;
  }
  a {
    display: inline-block;
    font-family: var(--body-font);
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-decoration: none;
  }
  a,a:focus, a:hover {
    text-decoration: none;
    outline: none;
    color: rgb(255, 255, 255);
  }
  p, span, li {
    margin: 0;
    font-family: var(--body-font);
    font-size: 18px;
    font-weight: 400;
    color: rgb(71 47 125);
    line-height: 1.556;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  ul, ol {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  button:focus{
    outline: none !important;
    box-shadow: none !important;
  }
  input:focus,
  textarea:focus {
    outline: none;
    box-shadow: none;
  }
  button {
    border: none;
    cursor: pointer;
  }
input, textarea {
    padding: 10px 25px;
    border: 1px solid #cccccc;
    width: 100%;
    font-family: var(--body-font);
}
select {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    outline: none;
}
textarea {
    min-height: 150px;
    resize: none;
}
span{
    display: inline-block;
}

/* Course Admission start*/
.course_addmission_main {
    margin-top: 150px;
  }
  main {
    box-sizing: border-box;
    margin: auto;
    padding: 0;
    font-size: 16px;
    max-width: 1140px;
    display: flex;
    
  }
  
  #root > div {
    width: 100%;
  }
  div main {
    width: 92vw;
    margin: 0 auto;
    display: flex;
    box-sizing: border-box;
    
  
    
  }
  main * {
    font-family: "Hind Siliguri", sans-serif !important;
    
  }
  .course__top__section {
    max-width: 60%;
    margin: 0 auto;
    margin-left: 0;
    color: #3b3036;
  }
  .course__top__section__wrapper {
    width: 100%;
    padding: 1rem;
  }
  .course__top {
    width: 100%;
  }
  .box__class_tab {
    width: 100%;
    padding-top: 1rem;
    /* background-color: #F2ECFF; */
  }
  .box__class_tab [aria-label="basic tabs example"] {
  }
  /* .course__top_wrapper{
      width: 70vw;
      margin: 0 auto;
    } */
  
  
  
  .top-h1 h1 {
    color: #f23460;
    font-family: "Hind Siliguri";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 52px;
  }
  .top-p * {
    text-align: justify;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #6b6b6b !important;
  }
  .top-image {
    /* width: 60%; */
  }
  .top-image img {
    width: 100%;
    object-fit: contain;
    border-radius: 5px;
    /* .embed-container iframe, .embed-container object, .embed-container embed */
  
    position: absolute; top: 0; left: 0; width: 100%; height: 100%; clip-path: inset(1px 1px);
  
  }
  
  .course__top_wrapper .tabs {
    display: flex;
    justify-content: space-around;
  }
  .course__description > div {
    font-weight: 400;
    text-align: justify;
    /* color: rgba(0, 0, 0, 0.8); */
  }
  .course__description h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 39px;
  }
  .course__description > div > p {
      font-style: normal;
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 32px !important;
      color: #6B6B6B !important;
  }
  
  .syllabus__section h2{
      font-family: 'Hind Siliguri';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  }
  .card__s {
    background-color: #ffffff;
    /* min-width: 100%; */
    padding: 1.5rem;
    border-radius: 10px;
    transition: all 0.3s ease;
    border: 1px solid #F23460;
  }
  .card__s:hover {
    box-shadow: 4px 4px 11px -4px rgb(0 0 0 / 22%);
  }
  .card__header {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
  }
  .card__img {
    height: 80px;
    width: 80px;
  }
  .card__img > svg {
  background-color: #FFCAD6;
    width: 100%;
    height: auto;
    border-radius: 50%;
    padding: 0.6rem;
  }
  
  .card__text h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #3F3F3F;
  }
  .card__body {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      /* or 178% */
      
      
      color: #6B6B6B;
  }
  
  .email__subcription {
    width: 90%;
    margin: 0 auto;
  }
  .email__subcription p {
    text-align: center;
    font-weight: 500;
    font-size: 1.1rem;
  }
  .form__sub {
    width: 100%;
  }
  .form__contrl {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* max-width: 100%; */
  }
  
  .input__fields {
    width: 100%;
    padding: 0.5rem;
  }
  .input__fields input,
  select {
    width: 100%;
    padding: 0.4rem;
    border: none;
    box-shadow: inset 0 1px 1px rgba(224, 208, 208, 0.18);
    outline: none;
    border: 1px solid #ccc;
    /* font-size: 1rem; */
    font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  
  }
  .input__fields input:focus {
    border-color: #F23460
    ;
    box-shadow: inset 0 1px 1px rgba(135, 71, 71, 0.08), 0 0 8px rgba(132, 133, 133, 0.6);
  }
  
  .input__fieldss {
    padding: 0.4rem;
  }
  .input__fieldss button {
    color: #ffffff;
    background: #F23460;  border-color: #ccc;
    width: 100%;
    text-transform: uppercase;
    word-spacing: 1px;
    font-size: 1rem;
    padding: 0.4rem;
    border: 1px solid rgb(101, 101, 101);
    /* margin-left: 0; */
    cursor: pointer;
    font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  
  }
  
  .course__end__part {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: 120px 1fr;
    /* justify-content: flex-start; */
    /* align-items: baseline; */
  }
  
  .course__end__part__left {
    padding: 0.5rem;
  }
  .course__end__part__right > p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      /* or 178% */
      
      
      color: #6B6B6B;
  }
  .gutter__div {
    margin: 1.3rem 0;
  }
  
  /**************************** project and assignment css *************************/
  .project__assignment_lists {
    /* display: grid;
      grid-template-columns: auto auto;
      grid-gap: 1rem; */
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    
  }
  .project__assignment_lists  .card__s {
    /* flex-basis: 2rem !important; */
    max-width: 45%;
    flex-shrink: 1;
    flex-grow: 1;
    /* background: #F2ECFF; */
  }
  .project__assignment_lists .card__s:nth-child(even) {
    border: 1px solid #F23460;
  }
  .project__assignment_lists .card__s:nth-child(odd) {
    border: 1px solid #5C2CC5;
  }
  .project__assignment_lists .card__s .card__top {
    display: flex;
    flex-flow: row wrap;
  }
  .project__assignment_lists .card__s .card__top {
    font-size: 14px !important;
  }
  .project__assignment_lists .card__s .card__top {
    display: flex;
    margin-bottom: 1rem;
  }
  
  .project__assignment_lists .card__s .card__top > div {
    margin: 0 0.2rem;
    background-color: #c850c0;
    border-radius: 50px;
    height: 1.4rem;
    display: flex;
    align-items: center;
  }
  .project__assignment_lists .card__s .card__top > div:nth-child(2) {
    background: #F2ECFF;
  }
  .project__assignment_lists .card__s .card__top > div:nth-child(2) span {
    color: #c850c0;
  }
  .project__assignment_lists .card__top > span* {
    font-size: 10px !important;
  }
  .project__assignment_lists .card__s .card__top > div > span {
    padding: .6rem;
    font-weight: 500;
    color: white;
    font-size: 14px;
  }
  .project__assignment_lists .card__bottom {
    font-size: 1.3rem;
    padding: 1rem 0;
    font-weight: 600;
  }
  .tabs {
    /* max-width: 100%; */
    min-width: 100px;
  }
  
  .tabs button {
  font-size: 1rem;
  max-width: 25%;
  font-family: 'Hind Siliguri';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  }
  .tabs button:nth-child(4) {
    /* max-width: 25%; */
  }
  .tabs {
  }
  
  /*************************************** carrer track div ************************************/
  .career__track {
    background-color: #F2ECFF;
    /* min-width: 100%; */
    padding: 2rem;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  
  .career__track__div {
  }
  .career__track__wrapper h2{
      font-family: 'Hind Siliguri';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 39px;
      /* identical to box height */
      
      text-align: center;
      
      color: #5C2CC5;
  }
  
  /************************************* commnets slider **************************************************/
  .carosol {
    /* padding: 1rem; */
    background-color: #FFFFFF;
    width: 98%;
    margin: auto;
    border-radius: 5px;
    height: auto;
  }
  .slick-list {
    -webkit-transition: height 250ms ease-in;
  }
  .carosel__div {
    border-radius: 5px;
    display: block;
    padding: 1rem;
    /* max-height: 500px; */
    /* border: 1px solid #F23460; */
  
  }
  .slick-prev:before, .slick-next:before{
      color: #F23460 !important;
  }
  
  .carosel__div__top {
    text-align: center;
  }
  
  .carosel__div__middle {
    width: 90%;
    margin: auto;
  }
  .carosel__div__middle p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      /* or 167% */
      
      text-align: center;
      
      color: #6B6B6B;
  }
  
  .carosel__div__bottom {
    display: flex;
    align-items: center;
    width: 50%;
    margin: auto;
  }
  .user__profile__img {
    padding: 1rem;
  }
  .user__profile__img img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
  .user__profile__name {
  }
  .user__profile__name span {
    font-weight: 600;
  }
  .comment__slider {
    transition: height 0.3s ease;
  }
  .slick-dots {
    /* position: relative !important; */
  }
  .slick-active svg{
    fill: #F23460;
  }
  .slick-dots li button::before {
    font-size: 1rem !important;
  }
  
  .slick-dots > button {
    padding: 0;
    background: #aaa;
    border: 1px solid #aaa;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    font-size: 0;
    outline: 0;
  }
  
  /************************************* faq **************************************************/
  
  .faq__div {
    margin-top: 4.5rem;
  }
  .faq__div__wrapper h2{
      margin: 30px 0;
      font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */
  
  text-align: center;
  
  color: #5C2CC5;
  }
  /************************* instructor **********************/
  .instructor__div{
    width: 100%;
  }
  .instructor__wrapper {
    background-color: #cccccc;
    border-radius: 5px;
    border: 1px solid #F23460;
    width: 100%;
  
  }
  .course__instructor {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 5px;
    /* border: solid; */
    border-radius: 1px solid ;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  
    margin-bottom: 1px;
  }
  .instruuctor__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
  }
  .instruuctor__image {
    /* width: 120px; */
    /* height:120px;
      border: 1.5px solid;
      display: flex;
      border-radius: 50%; */
  }
  /* .instruuctor__image img{
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    
    } */
  
  .instruuctor__name {
  }
  .instruuctor__name h2 {
    /* font-size: 2rem; */
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height, or 125% */
  
  
  color: #3F3F3F;
  }
  .instruuctor__name p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 30px;
      /* identical to box height, or 250% */
      
      letter-spacing: 0.05em;
      
      color: #3F3F3F;
  }
  .insturctor__details {
    padding: 2rem;
  
    background-color: #ffffff;
    /* border-radius: 1px solid; */
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .insturctor__details p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      /* or 188% */
      
      
      color: #6B6B6B;
  }
  
  /********************************************* sticky sidebar *******************************/
  .sticky__sidebar__section {
    max-width: 40% !important;
    background-color: #5C2CC5;
    border-radius: 10px;
    position: sticky !important ;
    max-height: 31rem;
    top: 7rem  !important;
    /* right: 1% !important; */
    /* left: 10px !important; */
    z-index: 2;
    /* transition: top .1s ease-in; */
    /* margin-left: 0%; */
  }
  .course_side_content {
    background-color: #5C2CC5;
    border-radius: 10px;
    height: auto;
  }
  
  .sticky__sidebar__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    color: white !important;
    /* color: rgb(7, 6, 6); */
  }
  .sticky__sidebar__wrapper h3 {
    font-size: 1.5rem;
  }
  
  .sticky__syllabus__content {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* align-items: center; */
    grid-gap: 0 1rem;
  }
  
  .sticky__content_div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .sticky__content_div .sticky__icon {
    margin-right: 0.5rem;
    color: #c5c13e;
  }
  .sticky__content_div .sticky__content {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
    color: white !important;
  }
  .coures__pricing {
    /* background-color:linear-gradient( 45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%); */
    background: #7850CF;
    padding: 1rem;
    text-align: center;
    display: flex;
    justify-content: space-around;
    flex-flow: column;
    width: 98%;
    border-radius: 5px;
  }
  .coures__pricing > div{
      display: flex;
      justify-content: space-between;
  }
  .coures__pricing span{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height */
      
      
      color: #FFFFFF;
  }
  
  .coures__pricing .course__amount {
    font-weight: 700;
    font-size: 24px;
  line-height: 36px;
  }
  .course__amount_installment {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  }
  .sticky__btn {
    /* padding: 1rem 0; */
  
    margin-top: 1rem;
    display: inline-flex;
    justify-content: space-around;
    width: 100%;
  }
  .sticky__btn span {
    width: 48%;
    display: block;
  }
  .sticky__btn > a {
    cursor: pointer;
    width: 100%;
    font-size: 1rem;
    display: block;
  }
  
  .sticky__btn a {
    background-color: #F23460;
    border-radius: 10px;
    color: black;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    display: block;
    padding: 1rem 0;
    text-align: center;
  }
  .div__overlay {
    width: 100vw;
    background-color: #F2ECFF;
    position: absolute;
    left: 0;
    top:0;
    z-index: -1;
  }
  .sticky__bottom {
    position: sticky;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    /* max-height: 2rem; */
    background-color: #5C2CC5;
    padding: 1rem;
    z-index: 999;
  }
  .sticky__bottom > .coures__pricing {
      width: 30%;
    font-weight: 700;
  }
  .priceing {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1.2rem;
  }
  .priceing span {
    font-weight: 700;
    font-size: 1.5rem;
  }
  .admission_now {
    background-color: rgb(242, 52, 96);
  }
  .download__syllabus {
    background-color: #55ef8e;
  }
  
  .tabs button[aria-selected="true"] {
    background-color: #5C2CC5;
    color: white;
  }
  
  /* footer{
      height: 150vh;
      background-color: gray;
    } */
  
  .MuiTabs-indicator {
    display: none;
  }
  .accodion [aria-expanded="true"] p,
  .accodion [aria-expanded="true"] svg {
    color: #6c43c3;
  }
  
  .btn__amount {
    display: none;
    color: white;
  }
  
  /**************** */
  @media only screen and (max-width: 950px) and (min-width: 769px){
    .project__assignment_lists  .card__s {
      /* flex-basis: 2rem !important; */
      max-width: 100%;
  
    }
    div main{
      margin: 0 0;
    }
    .course__top__section {
    max-width: 57%;
    }
    .tabs button {
      /* max-width: 20% !important; */
      /* font-size: 10px !important; */
    }
  }
  @media only screen and (max-width: 1000px) and (min-width: 769px){
   .instruuctor__header{
    display: flex;
    flex-direction: column;
   }
   .course__amount, .course__amount_installment{
    font-size: 1.3rem !important;
   }
  }
  
  @media only screen and (max-width: 1450px) {
    .sticky__sidebar__section {
      /* max-height: 39rem; */
  
    }
  }
  
  
  
  @media only screen and (max-width: 1250px) {
    .sticky__sidebar__section {
      max-height: 40rem;
  
    }
    .sticky__btn {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  
    .sticky__btn > span {
      width: 100%;
      display: block;
      margin-top: 1rem;
    }
    .sticky__btn a {
      width: 100%;
      display: grid;
      text-align: center;
    }
  }
  @media only screen and (max-width: 1150px) {
    .sticky__sidebar__section {
      max-height: 40rem;
    }
    .sticky__btn {
      padding: 0;
    }
  }
  @media only screen and (max-width: 1050px) {
    .sticky__sidebar__section {
      max-height: 39rem !important;
  
    }
    .tabs button {
      font-size: 0.8rem;
      /* max-width: 20% !important; */
      overflow: hidden;
    }
  }
  @media only screen and (min-width: 950px){
    .sticky__sidebar__section{
      margin-left: 2%;
    }
  }
  @media only screen and (max-width: 950px) {
    .sticky__sidebar__section {
      max-height: 39rem !important;
  
    }
    .carosel__div__bottom {
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 850px) {
    .sticky__sidebar__section{
      max-height: 42rem !important;
  
    }
    .sticky__content {
      font-size: 0.9rem;
    }
  }
  @media only screen and (max-width: 800px) {
    .sticky__sidebar__section {
      max-height: 44rem !important;
      /* height: 44rem !important; */
    }
  }
  @media only screen and (max-width: 768px) {
    .sticky__sidebar__section {
      height: 50 !important;
    }
    .course_addmission_main{
      margin:150px auto;
    }
  }
  
  @media only screen and (max-width: 769px) {
    
    main {
      width: 90%;
    }
    .course__top__section {
      max-width: 100%;
    }
    .course__top__section__wrapper {
      width: 100%;
    }
    .minified__pannel > div {
      margin: 2rem 0;
    }
    .input__fields button {
      width: 100%;
    }
    .sticky__sidebar__section {
      display: none;
    }
    .sticky__sidebar__section {
      max-height: 50%;
    }
    .coures__pricing {
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 650px) {
    .project__assignment_lists  .card__s {
      /* flex-basis: 2rem !important; */
      max-width: 100%;
      /* background: #F2ECFF; */
    }
    .course__description h3 {
      text-align: center;
      font-size: 1.5rem;
      padding: 1.5rem 0;
    }
    .course__end__part {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .course__end__part__left {
      margin: 2rem 0;
    }
    .course__end__part__right h3 {
      text-align: center;
    }
    .minified__pannel > div {
      margin: 1rem 0;
    }
    .minified__pannel .card__s .card__header {
      flex-direction: column;
    }
    .minified__pannel .card__s .card__header > div {
      margin: 0.5rem 0;
    }
  
    .minified__pannel .project__assignment_lists {
      display: flex;
      flex-direction: column;
    }
    .sticky__syllabus__content {
      display: flex;
      flex-direction: column;
    }
    .syllabus__section {
      text-align: center;
    }
    .sticky__content_div {
      display: flex;
      flex-direction: column;
    }
    .sticky__btn {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .sticky__btn > div {
      width: 100%;
      display: block;
      margin-top: 1rem;
    }
    .sticky__btn a {
      width: 100%;
      display: grid;
      text-align: center;
    }
    .coures__pricing {
      padding: 2rem;
      width: 100%;
      flex-direction: column;
      justify-content: space-around;
    }
  
    .coures__pricing > span {
      font-size: 1.5rem;
      font-weight: 700;
    }
    .card__text {
      text-align: center;
    }
    .card__header {
      gap: 0;
    }
    .priceing {
      /* background-color: #c5c13e; */
      display: none;
    }
    .download__syllabus {
      /* background-color: #55ef8e; */
      display: none;
    }
    .sticky__bottom > .coures__pricing {
      width: 90%;
      margin: auto;
      padding: 1rem;
    }
    .btn__amount {
      display: inline-block;
    }
    .sticky__bottom {
      height: auto;
    }
    .input__fieldss button {
      width: 80%;
    }
    .instruuctor__header {
      flex-direction: column;
    }
  }


/* Course Admission end */

.bg_img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
}
.title span {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    font-weight: inherit;
}
.bg_img {
    background-position: center;
    background-size: cover;
}
.mr-1, .mx-1 {
    margin-right: 0.25rem !important;
}
.mr-60 {
    margin-right: 60px;
}
.mt-1, .my-1 {
    margin-top: 0.25rem !important;
}
.button-group {
    display: inline-flex;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: center;
}
.b-none {
    border: none !important;
}
.section-header{
    margin-top: -6px;
    margin-bottom: 56px;
}
.sub-title{
    font-size: 24px;
    font-family: var(--body-font);
    color: rgb(162 96 0);
    font-weight: 500;
    line-height: 1.417;
    text-transform: uppercase;
    margin-bottom: 11px;
}
.sub-title-alt{
    font-size: 24px;
    font-family: var(--body-font);
    color: #09d4ea;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.417;
    margin-bottom: 11px;
}
.title{
    font-family: var(--body-font);
    font-size: 60px;
    color: rgb(49, 21, 102);
    font-weight: bold;
    line-height: 80px;
    margin-bottom: 21px;
}
iframe {
    border:0;
    width: 100%;
}
.social-area h4{
    color: rgb(67, 22, 159);
    margin-right: 45px;
}
.social-area .social a{
    border-radius: 50%;
    background-image: linear-gradient( 35deg, rgb(23,105,255) 1%, rgb(3,74,204) 100%, rgb(23,105,255) 100%);
    box-shadow: 0px 15px 18px 0px rgba(23, 105, 255, 0.42);
    width: 50px;
    height: 50px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.social-area .social .instagram{
    background-image: linear-gradient( 71deg, rgb(255,221,85) 1%, rgb(255,221,85) 10%, rgb(255,84,62) 50%, rgb(200,55,171) 100%);
    box-shadow: 0px 15px 18px 0px rgba(255, 69, 141, 0.42);
}
#contact-me .social-area .social .dribbble{
    background-image: unset;
}
.social-area .social .dribbble{
    background-color: rgb(255, 69, 141);
    box-shadow: 0px 15px 18px 0px rgba(255, 69, 141, 0.42);
    background-image: none;
}
.social-area .social .linkedin{
    background-image: linear-gradient( -90deg, rgb(123,195,255) 0%, rgb(33,118,204) 100%);
    box-shadow: 0px 15px 18px 0px rgba(5, 76, 207, 0.42);
}
@media (max-width: 991px) {
    .section-title{
        font-size: 40px;
    }
}
@media (max-width: 575px) {
    .sub-title,
    .sub-title-alt{
        font-size: 12px;
    }
}
.section-title span{
    color: var(--primary-color);
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    font-weight: inherit;
}
.shadow-none {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.mb-30-none{
    margin-bottom: -30px;
}
.mb-30{
    margin-bottom: 30px;
}
.mt-30 {
    margin-top: 30px;
}
.pt-120 {
    padding-top: 120px;
}
@media (max-width: 991px) {
    .pt-120 {
        padding-top: 60px;
    }
}
@media (max-width: 575px) {
    .pt-120 {
        padding-top: 50px;
    }
}
.pb-120 {
    padding-bottom: 120px;
}
@media (max-width: 991px) {
    .pb-120 {
        padding-bottom: 60px;
    }
}
@media (max-width: 575px) {
    .pb-120 {
        padding-bottom: 50px;
    }
}
body::-webkit-scrollbar {
    width: 4px;
}
body::-webkit-scrollbar-track {
    background: transparent; 
}
body::-webkit-scrollbar-thumb {
    background: #3a395e; 
}
body::-webkit-scrollbar-thumb:hover {
    background: #3a395e; 
}
@media (max-width: 991px) {
    .header-section .header-top {
        display: block;
    }
}
@media (max-width: 991px) {
    .navbar-collapsed {
        max-height: 280px;
        overflow: auto;
    }
    /* .navbar-collapsed .collapse{
        display: inline !important;
    } */
}

.navbar-collapsed .main-menu .menu_has_children.show .sub-menu {
    display: grid;
}
@media (max-width: 991px) {
    .menu_has_children .sub-menu {
        display: none !important;
    }
    .menu_has_children .sub-menu li {
        width: 100% ;
    }
    .navbar-collapsed .main-menu .menu_has_children.show .sub-menu {
        display: inline-flex !important;
        flex-wrap: wrap;
        margin-left: 20px;
    }
    .navbar-collapsed{
        display: inline;
    }
}

.navbar-collapsed .main-menu li:last-child .sub-menu {
    left: auto;
    right: 0;
}
.navbar-collapsed .main-menu .cart span{
    background-color: rgba(183, 165, 255, 0.2);
    color: #fff;
    padding: 0 20px;
    border-radius: 15px;
    margin-left: 5px;
}
.navbar-collapsed .main-menu .cart-area {
    top: 40px;
}
.navbar-collapsed .main-menu .cart-area button{
    background-color: transparent;
    color: var(--body-color);
    margin: 0 15px;
    font-weight: 500;
}
.navbar-collapsed .main-menu li {
    position: relative;
}

/* .navbar-collapsed .main-menu li:active {
    background-image: linear-gradient(45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
} */
/* .active{
    background-image: linear-gradient(45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
} */
.navbar-collapsed .main-menu li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}
.navbar-collapsed .main-menu li.menu_has_children {
    position: relative;
}
.navbar-collapsed .main-menu li.menu_has_children i {
    font-size: 18px;
    margin-left: 5px;
}
.visible-list{
    visibility: visible;
}
@media (max-width: 991px) {
    .navbar-collapsed .main-menu li.menu_has_children::before {
        top: 12px;
        right: 15px;
    }
}
.navbar-collapsed .main-menu li a {
    padding: 40px 25px;
    font-weight: 800;
    font-family: var(--body-font);
    font-size: 18px;
    text-transform: capitalize;
}
@media (max-width: 991px) {
    .navbar-collapsed .main-menu li a {
        padding: 10px 8px;
        display: block;
    }
    .navbar-expand-lg {
        float: right;
        width: 100%;
    }
}
.navbar-collapsed .main-menu li .sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 230px;
    background-color: var(--body-color);
    padding: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 9;
}
.navbar-collapsed .main-menu li .sub-menu li a {
    color: rgb(59, 54, 140) !important;
    padding: 15px 20px;
    display: block;
    border-bottom: 1px solid #ddd;
}
.navbar-collapsed .main-menu li .sub-menu li a:hover {
    color: var(--body-color) !important;
    border-radius: 0 !important;
}
.nav-area{
    display: contents;
    justify-content: space-between;
    z-index: 100;
}
@media (max-width: 991px) {
    .navbar-collapsed .main-menu li .sub-menu {
        position: initial;
        opacity: 1;
        visibility: visible;
        display: none;
        -webkit-transition: none;
        transition: none;
    }
}
.menu-toggle {
    margin: 15px 0;
    position: relative;
    display: block;
    width: 35px;
    height: 20px;
    cursor: pointer;
    background: transparent;
    border-top: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    font-size: 0;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}
.menu-toggle.is-active {
    border-color: transparent;
}
.menu-toggle::before, .menu-toggle::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #ffffff;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: -webkit-transform 0.25s ease-in-out;
    -webkit-transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
.menu-toggle.is-active::before {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
}
.menu-toggle.is-active::after {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
}
.navbar-toggler {
    padding-right: 0;
    display: none;
    position: sticky !important;
}
.toggle-btn{
    padding: 8px 12px;
    background-color: var(--primary-color);
    color:#ffffff;
    font-weight: 500;
    text-transform: capitalize;
    border: 1px solid transparent;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 4px;
}
.header-section .header-bottom {
    padding: 10px 10px;
}
@media (max-width: 991px) {
    .navbar-toggler{
        display: inline-block;
    }
    .navbar-light .navbar-toggler {
        position: absolute;
        right: 14%;
        margin-top: 5px;
        border: none;
    }
    #header-section .right-area {
        position: absolute;
        right: 20%;
        top: 20px;
    }
    .header-area{
        position: relative;
        padding: 15px 0;
    }
    #header-section .navbar .navbar-brand img{
        max-width: initial;
    }
    #header-section .navbar .navbar-brand {
        margin-top: -5px;
        width: 35%;
    }
    .navbar-collapsed .main-menu {
        padding-top: 30px;
    }
    #header-section .navbar .navbar-bottom{
        padding: 20px 0;
    }
    #header-section .navbar .navbar-top {
        justify-content: center !important;
    }
    #header-section {
        background-color: #5c2cc5 !important;
    }
    #header-section .overlay .navbar-toggler i{
        color: var(--body-color);
    }
    #header-section .navbar .navbar-nav .menu_has_children::after {
        transform: translateX(70px);
        left: 0;
    }
    
}
@media (max-width: 800px) {
    .navbar-light .navbar-toggler {
        right: 4%;
    }
}
@media (max-width: 440px) {
    #header-section .navbar .navbar-top .language-select-list img{
        display: none;
    }
    #header-section .navbar .navbar-top a {
        margin: 0 6px !important;
        font-size: 12px;
    }
    #header-section .navbar .navbar-top .language-select-list {
        margin: 0 !important;
    }
    #header-section .navbar .nice-select .option,
    #header-section .navbar .nice-select.select-bar .current{
        font-size: 12px;
    }
    #header-section .navbar .navbar-brand img {
        max-width: initial;
        width: 115px;
    }
    .navbar-light .navbar-toggler{
        margin-top: 0 !important;
    }
    #header-section .navbar .navbar-collapsed .navbar-nav {
        padding-right: 0;
    }
}
.scrollToTop {
    position: fixed;
    bottom: 0;
    right: 30px;
    width: 45px;
    height: 45px;
    background-image: linear-gradient(45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    border-radius: 5px;
    color: #fff;
    line-height: 45px;
    font-size: 20px;
    text-align: center;
    z-index: 9;
    cursor: pointer;
    transition: all 1s;
    transform: translateY(100%);
}
.scrollToTop.active {
    bottom: 30px;
    transform: translateY(0%);
    color: #fff;
}
.scrollToTop:hover{
    color: #fff;
}
.preloader{
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 999999999;
    overflow: visible;
    background: #ffffff url(../image/preloader.gif) no-repeat center center;
    background-size: 400px 400px;
}
  #header-section.header-fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999;
        background-color: #5c2cc5;
        box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 30%);
    }
    #header-section.header-fixed .header-top,
    #header-section.header-fixed .header-middle {
        display: none!important;
    }
    #header-section.header-fixed .header-bottom {
        background-color: var(--body-color);
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
    }
    #header-section .header-top {
        padding: 20px 0;
    }
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      -ms-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
    }
  }
  @keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      -ms-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
    }
  }
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }
  @keyframes zoomInOut {
    50% {
      -webkit-transform:scale(1.2);
      -moz-transform:scale(1.2);
      -ms-transform:scale(1.2);
      -o-transform:scale(1.2);
      transform:scale(1.2);
    }
}

/* cmn btn */
.cmn-btn {
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    background-image: linear-gradient( 45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    border-radius: 50px;
    padding: 12px 30px;
    z-index: 1;
    overflow: hidden;
    transition: 0.35s ease;
}
.cmn-btn:hover{
    color: rgb(255, 255, 255);
    box-shadow: 0px 26px 38px 0px rgba(254, 81, 149, 0.3);
    background-image: linear-gradient( 45deg, rgb(254,81,150) 0%, rgb(247,112,98) 100%);
}

/* cmn btn second */
.btn-icon {
    position: relative;
    content: '';
    background-color: white;
    top: -5px;
    left: 5px;
    height: 1px;
    width: 40px;
    display: inline-block;
    transition: all 0.7s;
}
.btn-icon::before{
    position: absolute;
    content: '';
    top: -4px;
    width: 10px;
    height: 10px;
    border-top: 1px solid;
    border-right: 1px solid;
    border-color: white;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    right: 1px;
    transition: all ease 0.3s;
}
.cmn-btn-second:hover .btn-icon::before{
    border-color: #ea43ad;
}
.cmn-btn-second{
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
}
.cmn-btn-second:hover .btn-icon {
    width: 60px;
    background-color: #ea43ad;
}

/* header area */
#header-section {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    /* background-color: transparent; */
    transition: 0.8s ease-in-out;
    -webkit-transition:  1s;
    /* transform: translateY(100%); */
    
    
}
#header-section .header-area{
    padding-left: 15px;
    padding-right: 15px;
}
#header-section .navbar .navbar-nav .dropdown a,
#header-section .navbar .navbar-nav li a{
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
}
#header-section .navbar .navbar-nav li a.active,
#header-section .navbar .navbar-nav li a:hover{
    background-image: linear-gradient(40deg, rgb(184,93,255) 0%, rgb(255,69,69) 100%);
    border-radius: 0 0 20px 20px;
}
#header-section .navbar .navbar-nav .menu_has_children a{
    padding-right: 40px;
}
#header-section .navbar .navbar-nav .menu_has_children{
    display: grid;
    align-items: center;
}
#header-section .navbar .navbar-nav .menu_has_children::after{
    position: absolute;
    content: '';
    background-image: url(../image/down-arrow.png);
    background-repeat: no-repeat;
    height: 7px;
    width: 15px;
    right: 15px;
    transition: all ease 0.3s;
}
#header-section .navbar .navbar-nav .dropdown{
    display: flex;
    align-items: center;
}
#header-section .navbar .navbar-nav .cart-area .cart-menu{
    display: table-column;
    transform: translateY(150px);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
#header-section .navbar .navbar-nav .cart-area .cart-menu.show{
    transform: translateY(-50px);
    opacity: 1;
    position: absolute;
    top: 100%;
    left: -45%;
    background-color: var(--body-color);
    padding: 0;
    width: 270px;
    z-index: 9;
    border-radius: 10px;
    box-shadow: 0px 22px 84px 0px rgb(127 153 202 / 42%);
}
#header-section .navbar .navbar-nav .cart-area .dropdown-items{
    border: 1px solid #e9e8e9;
    border-radius: 5px;
    padding: 8px 10px;
    margin: 15px 10px;
    position: relative;
}
#header-section .navbar .navbar-nav .cart-area .image-area img{
    max-width: initial;
    margin-right: 10px;
}
#header-section .navbar .navbar-nav .cart-area .text-area h5{
    font-size: 16px;
    font-family: var(--body-font);
    font-weight: 400;
    color: rgb(90, 62, 142);
    line-height: 1.5;
}
#header-section .navbar .navbar-nav .cart-area .text-area .qnt span,
#header-section .navbar .navbar-nav .cart-area .text-area .qnt{
    font-size: 13px;
    color: rgb(90, 62, 142);
    line-height: 1.846;
}
#header-section .navbar .navbar-nav .cart-area .text-area .price span,
#header-section .navbar .navbar-nav .cart-area .text-area .price{
    font-size: 16px;
    color: rgb(128, 70, 248);
    line-height: 1.5;
    font-weight: 500;
}
#header-section .navbar .navbar-nav li .cart-menu a:hover {
    background-image: unset;
    border-radius: 10px;
}
#header-section .navbar .navbar-nav li .cart-menu .view{
    background-image: linear-gradient(45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    font-size: 12px;
    padding: 10px 16px;
    border-radius: 20px;
    margin: 40px 0 30px;
}
#header-section .navbar .navbar-nav li .cart-menu .view:hover{
    background-image: linear-gradient( 45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    box-shadow: 0px 26px 38px 0px rgba(254, 81, 149, 0.3);
    border-radius: 20px;
}
#header-section .navbar .navbar-nav li .cart-menu .total-order p{
    font-size: 14px;
    color: rgb(86, 79, 101);
    line-height: 1.714;
}
#header-section .navbar .navbar-nav li .cart-menu .total-order span{
    font-size: 16px;
    color: rgb(128, 70, 248);
    line-height: 1.5;
    font-weight: 500;
}
#header-section .navbar .navbar-nav li .cart-menu button{
    position: absolute;
    right: -8px;
    top: -15px;
    background-color: transparent;
}
#header-section .navbar .navbar-nav li .cart-menu .total-order{
    border-top: 1px solid #e9e8e9;
    border-bottom: 1px solid #e9e8e9;
    padding: 6px 10px;
}
#header-section .navbar .navbar-nav li .cart-menu .total-order p {
    font-size: 14px;
    color: rgb(86, 79, 101);
    line-height: 1.714;
}
#header-section .navbar .navbar-nav li .cart-menu .total-order .dollar {
    font-size: 16px;
    color: rgb(128, 70, 248);
    line-height: 1.5;
}

/* banner-section */
#banner-section {
    position: relative;
    padding-top: 170px;
    background-image: url(../image/banner-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
#banner-section .banner-content{
    position: relative;
}
#banner-section .banner-content .main-content{
    position: relative;
}
#banner-section .banner-content .main-content .item{
    position: absolute;
    display: flex;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 38px;
    padding: 17px 28px;
}
#banner-section .banner-content .main-content .item img{
    margin-right: 15px;
    width: 40px;
}
#banner-section .banner-content .main-content .item h5{
    color: rgb(49, 21, 103);
    font-weight: 500;
}
#banner-section .banner-content .main-content .item.one{
    top: 20%;
    left: 70px;
}
#banner-section .banner-content .main-content .item.two{
    bottom: 25%;
    box-shadow: 2.3px 21.879px 79px 0px rgba(219, 210, 255, 0.80);
}
#banner-section .banner-content .main-content .item.three{
    top: 20%;
    right: 50px;
}
#banner-section .banner-content .main-content .item.four{
    bottom: 25%;
    right: -20px;
    box-shadow: 2.3px 21.879px 79px 0px rgba(219, 210, 255, 0.80);
}
#banner-section .text-area h5{
    font-size: 18px;
    color: #8C3A32;
    line-height: 1.556;
}
#banner-section .text-area h2{
    font-size: 78px;
    color: rgb(49, 21, 103);
    font-family: 'GlacialIndifference';
    font-weight: 800;
    line-height: 88px;
    margin: 0 0 40px 0;
}
#banner-section .text-area h2 span{
    color: #8C3A32;
    font-weight: 800;
    font-family: 'GlacialIndifference';
    font-size: inherit;
}
#banner-section .text-area .btn-area .cmn-btn{
    margin-right: 30px;
}
#banner-section .text-area .btn-area .cmn-btn-second{
    color: rgb(49, 21, 103);
    text-transform: uppercase;
}
#banner-section .text-area .btn-area .cmn-btn-second .btn-icon{
    background-color: #311567;
}
#banner-section .text-area .btn-area .cmn-btn-second .btn-icon::before{
    border-color: #311567;
}
#banner-section .right-area{
    position: relative;
}
#banner-section .right-area .download-area img {
    margin-left: 10px;
}
#banner-section .right-area .download-area {
    display: contents;
}
#banner-section .right-area .download{
    font-size: 20px;
    font-weight: 500;
    padding: 12px 25px;
    color: rgb(29, 12, 64);
    line-height: 1.4;
    border-radius: 35px;
    background-color: var(--body-color);
    box-shadow: 1.463px 13.923px 57px 0px rgba(247, 6, 115, 0.35);
    transform: translateY(-110px);
}
/* Student Say */
.student_card{
    transition: box-shadow .3s;
    /* border: 2px solid red; */
    padding: 10px 0 10px 0;
    box-shadow: 0px 0px 11px 11px rgba(62, 61, 61, 0.1) !important;
}
/* .student_card:hover{
    box-shadow: 0px 0px 11px 11px rgba(62, 61, 61, 0.1);
} */

/* Specilizing In */
#specilizing-section {
    background-color: #fafbff;
}
#specilizing-section .single-item {
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 2.3px 21.879px 79px 0px rgb(196 184 246 / 25%);
    padding: 40px 25px 47px;
    text-align: center;
    height:300px
}
#specilizing-section .single-item h2{
    color: rgb(80, 51, 140);
    margin-top: 27px;
}

/* Latest Project */
#latest-project {
    background-color: #f7f4fd;
}
#latest-project .single-item .text-area a{
    display: block;
}
#latest-project .single-item{
    padding: 30px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 2.3px 21.879px 79px 0px rgba(99, 83, 165, 0.25);
    margin-bottom: 30px;
}
#latest-project .single-item:last-child{
    margin-bottom: 0;
}
#latest-project .single-item .text-area {
    margin-top: 10px;
}
#latest-project .single-item h5{
    font-size: 18px;
    color: rgb(121 71 0);
    background-color: #fff4e5;
    padding: 13px 34px;
    border-radius: 25px;
    display: inline-block;
    font-weight: 500;
    line-height: 1.556;
}
#latest-project .single-item h5.mid{
    color: rgb(90 41 191);
    background-color: #efeafa;
}
#latest-project .single-item h5.last{
    color: rgb(23 95 3);
    background-color: #e9f7e6;
}
#latest-project .single-item h2{
    font-size: 28px;
    color: rgb(58, 57, 94);
    font-weight: 800;
    line-height: 1.357;
    margin: 26px 0 24px 0;
}
#latest-project .single-item .text-area p{
    color: rgb(92 85 107);
}
#latest-project .single-item .text-area .btn-icon {
    background-color: rgb(90, 62, 142);
}
#latest-project .single-item .text-area .btn-icon::before{
    border-top: 1px solid;
    border-right: 1px solid;
    border-color: rgb(90, 62, 142);
}
#latest-project .single-item .text-area .cmn-btn-second:hover .btn-icon::before{
    border-color: #ea43ad;
}
#latest-project .single-item .text-area .cmn-btn-second{
    color: rgb(90, 62, 142);
    margin-top: 30px;
    text-transform: uppercase;
}
#latest-project .single-item .text-area .cmn-btn-second:hover .btn-icon {
    background-color: #ea43ad;
}
#latest-project .single-icon {
    border-radius: 50%;
    background-color: rgb(255, 69, 141);
    box-shadow: 0px 15px 18px 0px rgb(255 69 141 / 42%);
    width: 60px;
    height: 60px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#latest-project .single-icon.instagram{
    background-image: linear-gradient( 71deg, rgb(255,221,85) 1%, rgb(255,221,85) 10%, rgb(255,84,62) 50%, rgb(200,55,171) 100%);
    box-shadow: 0px 15px 18px 0px rgba(255, 69, 141, 0.42);
}
#latest-project .single-icon.behance{
    background-image: linear-gradient( 35deg, rgb(23,105,255) 1%, rgb(3,74,204) 100%, rgb(23,105,255) 100%);
    box-shadow: 0px 15px 18px 0px rgba(23, 105, 255, 0.42);
}
#latest-project .bottom-area h2 {
    color: rgb(58, 57, 94);
    font-weight: 500;
    margin: 23px 0 25px 0;
}
#latest-project .bottom-area h2 span {
    color: #3a0aff;
    font-size: inherit;
    font-weight: inherit;
}

/* Now Your Turn */
#now-your-turn {
    position: relative;
    background-color: rgb(98, 45, 207);
    margin-bottom: 120px;
}
#now-your-turn .overlay .section-header {
    margin-bottom: 40px;
}
#now-your-turn .overlay .section-header .cmn-btn{
    margin-top: 36px;
}
#now-your-turn .overlay .section-header .title,
#now-your-turn .overlay .section-header p{
    color: rgb(240, 232, 255);
}
#now-your-turn .right-area{
    position: absolute;
    right: 8%;
    top: -8%;
}
#now-your-turn .bottom-area .single-item{
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 1.463px 13.923px 57px 0px rgba(43, 0, 132, 0.1);
    padding: 69px 0 76px;
    text-align: center;
}
#now-your-turn .bottom-area .single-item {
    margin-bottom: -120px;
}
#now-your-turn .bottom-area .single-item span {
    font-size: 80px;
    color: rgb(49, 21, 103);
    font-weight: 800;
    line-height: 1.125;
    text-transform: uppercase;
    margin-bottom: 6px;
}
#now-your-turn .bottom-area .single-item h5 {
    color: #8C3A32;;
    font-weight: 400;
}

/* Testimonials */
#testimonials .left-area{
    position: relative;
    background-image: url(../image/testimonials-slider-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
#testimonials .left-area img{
    transition: all 0.8s;
    padding: 35px 0 0 50px;
    transform: translate(45px, 660px);
    padding-top: 90px;
}
#testimonials .slick-slider {
    margin-bottom: -10px;
}
#testimonials .slick-active .left-area img{
    transform: translate(45px, -60px);
}
#testimonials .text-area {
    margin-top: -30%;
}
#testimonials .text-area p{
    margin: 13px 0 29px 0;
}
#testimonials .text-area h4 span{
    margin-left: 7px;
}
#testimonials .text-area h4,
#testimonials .text-area h3{
    color: rgb(90, 62, 142);
}
#testimonials .btn-icon {
    background-color: #a8a8a8;
    height: 2px;
}
#testimonials .btn-icon::before{
    border-color: #a8a8a8;
}
#testimonials .btn-icon:hover::before{
    border-color: #4f4f4f;
}
#testimonials .btn-icon:hover {
    width: 60px;
    background-color: #4f4f4f;
}
#testimonials .slick-initialized .slick-arrow {
    position: absolute;
    top: 73%;
    z-index: 1;
}
#testimonials .slick-initialized .slick-prev::before {
    top: -8px;
    transform: rotate(225deg);
    left: -1px;
}
#testimonials .slick-initialized .slick-prev {
    left: calc(60% - 95px);
}
#testimonials .slick-initialized .slick-next {
    left: calc(63% - 50px);
}
#testimonials.about{
    background-color: #f7f4fd;
}
#testimonials.portfolio {
    background-color: #f7f4fd;
}

/* worked with */
#worked-with{
    padding-top: 56px;
    padding-bottom: 58px;
    background-image: url(../image/brand-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
#worked-with .main-content{
    font-size: 36px;
    color: rgb(254, 254, 255);
    line-height: 1.278;
}
#worked-with .main-content h2{
    margin-bottom: 48px;
}
#worked-with .main-content .single-item{
    text-align: center;
    padding: 15px 20px;
}
#worked-with .slick-slide img {
    display: initial;
}
#worked-with .main-content .single-item img{
    margin: auto;
}
#worked-with .main-content .slick-slider {
    margin:0 -15px;
}
#worked-with .main-content .slick-slide {
    border: 1px solid #8153de;
    border-radius: 15px;
    margin-right: 15px;
    margin-left: 15px;
}

/* faq section */
#faq-section .overlay{
    margin-bottom: -22px;
}
#faq-section #accordian-one{
    margin-bottom: -20px;
}
#faq-section .overlay .card{
    border: none;
    margin-bottom: 20px;
}
#faq-section .overlay .card-header {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 9.272px 3.746px 38px 0px rgb(176 163 231 / 26%);
    border: none;
}
#faq-section .overlay .card-header .btn-link {
    width: 100%;
    font-size: 22px;
    color: rgb(59, 54, 140);
    line-height: 1.455;
    font-weight: 500;
    position: relative;
    text-align: left;
    text-decoration: none;
    padding: 4px 57px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

#faq-section .overlay .collapsing,
#faq-section .overlay .collapse {
    border-radius: 0px 0px 10px 10px;
    background-color: white;
    box-shadow: 9.272px 3.746px 38px 0px rgb(176 163 231 / 26%);
}
#faq-section .overlay .card-header .collapsed.btn-link::after {
    position: absolute;
    content: '';
    background-image: url(../image/right-arrow.png);
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
    right: 0;
    transform: rotate(0deg);
    transition: all ease 0.3s;
}
#faq-section .overlay .card-header .btn-link::after {
    position: absolute;
    content: '';
    background-image: url(../image/right-arrow.png);
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
    right: 0;
    transform: rotate(90deg);
    transition: all ease 0.3s;
}
#faq-section .overlay .card-header .btn-link::before {
    position: absolute;
    content: '';
    background-image: url(../image/faq-icon.png);
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
    left: 0;
}
#faq-section .overlay .card .card-body{
    color: rgb(99 73 154);
}

/* footer-section */
#footer-section .overlay{
    position: relative;
    background-image: url(../image/footer-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 5%;
}
#footer-section .overlay .section-header .cmn-btn{
    margin-top: 36px;
}
#footer-section .overlay .section-header {
    margin-bottom: -8px;
}
#footer-section .section-header .title{
    color: rgb(255, 255, 255);
    font-weight: 500;
}
#footer-section .bottom-area {
    padding-bottom: 30px;
}
#footer-section .bottom-area ul li{
    margin-left: 47px;
}
#footer-section .footer-bottom .main-content {
    padding: 22px 0;
}
#footer-section .footer-bottom .main-content .left-area p a{
    color: #a50a82;
}

/* inner-banner */
#banner-section.inner-banner{
    padding-top: 174px;
    padding-bottom: 0;
    background-image: url(../image/inner-banner-bg.png);
}
#banner-section.inner-banner .banner-content .social {
    position: initial;
}
#banner-section.inner-banner .banner-content .text-area h2{
    font-size: 28px;
    line-height: 28px;
    font-weight: 500;
    margin: 9px 0 24px 0;
}
#banner-section.inner-banner .banner-content .text-area h1{
    font-size: 60px;
    line-height: 70px;
    color: #fff;
    font-family: var(--body-alt-font);
}
#banner-section.inner-banner .banner-content .social-area h3{
    font-weight: 500;
    font-size: 24px;
    color: var(--body-color);
}
#banner-section.inner-banner .banner-content .single-icon {
    margin: 19px 0 20px 28px;
}

/* My Story */
#my-story-section .overlay {
    margin-top: -12px;
    margin-bottom: -32px;
}
#my-story-section .left-area p{
    margin-bottom: 27px;
}
#my-story-section .left-area h1{
    color: rgb(90, 62, 142);
    margin-bottom: 21px;
}
#my-story-section .right-area{
    position: relative;
}
#my-story-section .right-area .img-area{
    position: relative;
}
#my-story-section .right-area .img-area::after{
    position: absolute;
    width: 93%;
    height: 92%;
    content: "";
    left: 0;
    top: 0;
    background-color: rgb(246, 150, 121);
    z-index: -1;
    transform: translate(-35px, 65px);
    border-radius: 20px;
}
#my-story-section .right-area .object,
#my-story-section .right-area .main-img,
#my-story-section .right-area .user{
    position: absolute;
    right: 0;
}
#my-story-section .right-area .user{
    transform: translate(-120px, 77px);
}
#my-story-section .social {
    background-color: rgb(255, 255, 255);
    position: absolute;
    bottom: 50px;
    box-shadow: 9.272px 3.746px 38px 0px rgb(176 163 231 / 26%);
    border-radius: 50px;
    padding: 20px 50px;
}
#my-story-section .social .dribbble {
    background-image: none;
}

/* My Professional Skills */
#professional-skills {
    background-color: #fafbff;
}
#professional-skills .overlay{
    margin-bottom: 58px;
}
#professional-skills .header-item{
    position: relative;
    z-index: 1;
}
#professional-skills .skills-item{
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 2.3px 21.879px 79px 0px rgb(70 31 227 / 15%);
    position: relative;
    margin-top: 30px;
}
#professional-skills .skills-item .single-item {
    text-align: center;
    transition: all 0.3s;
}
#professional-skills .professional-carousel{
    transform: scaleY(2.8);
    height: 355px;
    padding: 110px 0 0 0;
}
#professional-skills .professional-carousel .single-item:hover {
    border-radius: 3%;
    background-color: rgb(124, 67, 243);
    box-shadow: 2.3px 21.879px 79px 0px rgba(70, 31, 227, 0.1);
    padding: 5px 0 30px 0;
    margin-top: -7px;
}
#professional-skills .professional-carousel .single-item:hover h3{
    color: var(--body-color);
}
#professional-skills .skills-item .single-item h3{
    transform: scaleY(0.34);
    color: rgb(90, 62, 142);
    font-weight: 500;
}
#professional-skills .skills-item .single-item .icon-area {
    border-radius: 50%;
    background-color: rgb(255, 186, 121);
    margin: auto;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -46px;
    margin-top: -13px;
    transform: scaleY(0.34);
}
#professional-skills .skills-item .single-item .icon-area.second{
    background-color: rgb(206, 232, 249);
}
#professional-skills .skills-item .single-item .icon-area.third{
    background-color: rgb(255, 173, 208);
}
#professional-skills .skills-item .single-item .icon-area.fourth{
    background-color: rgb(228, 182, 255);
}
#professional-skills .btn-icon {
    background-color: #a8a8a8;
    height: 2px;
}
#professional-skills .btn-icon::before{
    border-color: #a8a8a8;
}
#professional-skills .btn-icon:hover::before{
    border-color: #4f4f4f;
}
#professional-skills .btn-icon:hover {
    width: 60px;
    background-color: #4f4f4f;
}
#professional-skills .slick-initialized .slick-slide{
    opacity: 0;
}
#professional-skills .slick-initialized .slick-active{
    opacity: 1;
}
#professional-skills .slick-initialized .slick-list{
    overflow: inherit;
}
#professional-skills .slick-initialized .slick-arrow {
    position: absolute;
    top: 12%;
    transform: scaleY(0.4);
    z-index: 1;
}
#professional-skills .slick-initialized .slick-prev::before {
    top: -8px;
    transform: rotate(225deg);
    left: -1px;
}
#professional-skills .slick-initialized .slick-prev {
    left: calc(92% - 60px);
}
#professional-skills .slick-initialized .slick-next {
    left: calc(98% - 60px);
}

/* Past Experience */
#past-experience .single-item{
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: -3.742px 17.607px 73px 0px rgb(70 31 227 / 14%);
    padding: 50px;
    margin: 40px 0;
}
#past-experience .single-item h3{
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 12px;
    font-weight: 800;
}
#past-experience .single-item p a{
    color: rgb(23 95 3);
}
#past-experience .left-point{
    width: 24px;
    height: 24px;
    background-color: rgb(98, 45, 207);
    border-radius: 50%;
    position: relative;
}
#past-experience .left-point::after{
    position: absolute;
    content: "";
    background-color: #fff;
    box-shadow: -1.04px 4.891px 10px 0px rgba(70, 31, 227, 0.52);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: -3px;
    z-index: -1;
    left: -3px;
}
#past-experience .scroll-line{
    position: relative;
}
#past-experience .scroll-line::after{
    position: absolute;
    content: "";
    width: 8px;
    height: 75%;
    left: 8px;
    top: calc(22% - 100px);
    background: #dfd4f5;
    z-index: -2;
}
#past-experience .scroll-line::before{
    position: absolute;
    content: "";
    width: 8px;
    height: 35%;
    left: 8px;
    top: calc(22% - 100px);
    background: rgb(98, 45, 207);
    z-index: -1;
}

/* now your turn */
#now-your-turn.about {
    padding-bottom: 0;
    margin-bottom: 0;
}
#now-your-turn.about .overlay{
    margin-bottom: -10px;
}
#now-your-turn.about {
    margin-top: 153px;
}
#now-your-turn.about .top-area{
    position: relative;
    border-radius: 50%;
    background-image: linear-gradient(45deg, rgb(184,203,184) 0%, rgb(184,203,184) 0%, rgb(180,101,218) 0%, rgb(207,108,201) 33%, rgb(238,96,156) 66%, rgb(238,96,156) 100%);
    width: 395px;
    height: 395px;
    padding: 0 30px;
    margin-top: -315px;
}
#now-your-turn.about .top-area::before,
#now-your-turn.about .top-area::after{
    position: absolute;
    content: '';
    border: 1px solid #612dd0;
    border-radius: 50%;
    width: 90%;
    height: 90%;
}
#now-your-turn.about .top-area::before {
    left: 15%;
    top: 0%;
}
#now-your-turn.about .top-area::after{
    right: 14%;
    bottom: 5%;
}
#now-your-turn.about .top-area h1{
    color: var(--body-color);
    font-weight: 500;
    z-index: 1;
}

/* portfolio page  */
#banner-section.inner-banner.portfolio {
    padding-top: 228px;
    padding-bottom: 30px;
}
#banner-bottom.portfolio {
    height: initial;
    padding: 38px 0;
    background-color: #43169f;
}
#banner-bottom.portfolio .left-icon a span{
    border: 1px solid #7b5abb;
    border-radius: 50%;
    padding: 15px;
    margin-right: 20px;
}
#banner-bottom.portfolio form .subscribe {
    border-style: solid;
    border-width: 1px;
    border-color: rgb(99, 66, 255);
    background: #532ba9;
    border-radius: 30px;
}
#banner-bottom.portfolio form .subscribe input::placeholder {
    color: #fff;
    opacity: 1;
}  
#banner-bottom.portfolio form .subscribe input:-ms-input-placeholder {
   color: #fff;
} 
#banner-bottom.portfolio form .subscribe input::-ms-input-placeholder {
   color: #fff;
}
#banner-bottom.portfolio form .subscribe input{
    background: #532ba9;
    border: none;
    border-radius: 30px;
    color: var(--body-color);
}
#banner-section.inner-banner .banner-content .single-icon.dribbble{
    background-image: none;
}
#banner-bottom.portfolio form .subscribe button{
    font-size: 16px;
    color: rgb(255, 255, 255);
    line-height: 1.75;
    border-radius: 30px;
    background-image: linear-gradient(45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    box-shadow: 0px 26px 38px 0px rgb(254 81 149 / 30%);
    text-transform: uppercase;
    padding: 0 29px;
}

/* Latest Project */
#latest-project.portfolio {
    background-color: #fff;
}
#latest-project.portfolio .overlay .nav-link{
    font-weight: 500;
    border-radius: 25px;
    padding: 10px 50px;
}
#latest-project.portfolio .overlay .nav-tabs{
    border-bottom: none;
    justify-content: center;
    margin-bottom: 40px;
}
#latest-project.portfolio .overlay .nav-tabs .nav-item {
    margin: 0 10px;
}
#latest-project.portfolio .overlay .nav-link.active{
    background-image: linear-gradient( 45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    color: var(--body-color) !important;
}
#latest-project.portfolio .overlay .nav-link.all {
    color: rgb(90, 62, 142);
    background-color: #fff6ee;
}
#latest-project.portfolio .overlay .nav-link.design {
    color: rgb(23 95 3);
    background-color: #e9f7e6;
}
#latest-project.portfolio .overlay .nav-link.ui-ux {
    color: rgb(90 41 190);
    background-color: #eee9fa;
}
#latest-project.portfolio .overlay .nav-link.product {
    color: rgb(114 67 0);
    background-color: #ffeacc;
}

/* now-your-turn */
#now-your-turn.portfolio{
    margin-top: 194px;
}

/* portfolio-details */
#banner-section.portfolio-details .banner-content {
    position: relative;
    padding-bottom: 41px;
}
#banner-section.portfolio-details .text-area h2{
    color: rgb(255, 150, 0);
}
#banner-section.portfolio-details .text-right{
    text-align: end !important;
}
#banner-section.portfolio-details .right-side h5 {
    color: rgb(0, 255, 204);
    font-weight: 500;
    margin-bottom: 19px;
}
#banner-section.portfolio-details .right-side p {
    color: var(--body-color);
    font-weight: 400;
}
#banner-section.portfolio-details .right-side p span {
    color: rgb(255, 150, 0);
}

/* banner-bottom */
#banner-bottom.portfolio-details .items{
    margin: 0;
}
#banner-bottom.portfolio-details{
    height: initial;
    padding: 40px 0;
    background-color: #43169f;
}
#banner-bottom.portfolio-details .single-item{
    position: relative;
}
#banner-bottom.portfolio-details .single-item::after{
    position: absolute;
    content: '';
    width: 1px;
    height: 100%;
    background-color: rgb(141, 112, 202);
    top: 0;
    right: -80px;
}
#banner-bottom.portfolio-details .single-item:last-child:after{
    display: none;
}
#banner-bottom.portfolio-details .single-item p{
    color: rgb(234, 223, 255);
}
#banner-bottom.portfolio-details .single-item h3{
    color: rgb(255, 255, 255);
    margin-bottom: 7px;
}
#banner-bottom.portfolio-details .single-item p a{
    color: rgb(24, 255, 0);
    text-decoration: revert;
}

/* Project Details */
#project-details .main-content h1,
#project-details .main-content h5,
#project-details .main-content .title{
    color: rgb(49, 21, 105);
}
#project-details .main-content {
    margin-bottom: -11px;
}
#project-details .main-content img{
    margin-bottom: 60px;
}
#project-details .main-content p{
    margin-bottom: 27px;
}
#project-details .main-content h1{
    margin-bottom: 23px;
}
#project-details .main-content h5{
    margin-bottom: 54px;
    line-height: 2;
    font-weight: 500;
}
#project-details .main-content .project-carousel {
    margin-top: 50px;
}
#project-details .main-content .project-carousel h1 {
    margin-bottom: 56px;
}
#project-details .main-content .project-carousel .single-item{
    text-align: center;
    margin-top: 15px;
}
#project-details .main-content .project-carousel .single-item img{
    border-radius: 50%;
    margin: auto;
    margin-bottom: 24px;
}
#project-details .main-content .project-carousel .single-item .q-icon{
    border-radius: initial;
    margin: 0;
    display: inline-block;
}
#project-details .main-content .project-carousel .single-item sup{
    top: -20px;
}
#project-details .main-content .project-carousel .single-item h4{
    color: rgb(49, 21, 105);
}

/* product page */
#banner-section.inner-banner.product{
    padding-top: 177px;
    padding-bottom: 0;
}
#banner-section.product .main-content{
    border-radius: 20px;
    background-color: var(--body-color);
    box-shadow: -3.742px 17.607px 46px 0px rgb(70 31 227 / 24%);
    padding: 40px;
    margin-bottom: -65px;
}
#banner-section.product .banner-content .text-area h1 {
    margin-bottom: 81px;
}
#banner-section.product .main-content .right-item button{
    background-image: linear-gradient(45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    box-shadow: 0px 26px 38px 0px rgb(254 81 149 / 30%);
    border-radius: 10px;
    padding: 0 30px;
    margin: 3px 5px;
}
#banner-section.product .main-content .left-item .nice-select .current{
    margin-left: -15px;
}
#banner-section.product .main-content .left-item .nice-select{
    padding: 0 30px;
    height: 50px;
    line-height: 50px;
    margin-right: 30px;
}
#banner-section.product .main-content .left-item .nice-select,
#banner-section.product .main-content .right-item .subscribe{
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #cebef0;
    background-color: #f6f6ff;
}
#banner-section.product .main-content .right-item .subscribe input{
    background-color: #f6f6ff;
    border: none;
    border-radius: 10px;
    color: rgb(93 69 145);
}
#banner-section.product .main-content .right-item .subscribe input::placeholder {
    color: rgb(93 69 145);
    opacity: 1;
}  
#banner-section.product .main-content .right-item .subscribe input:-ms-input-placeholder {
    color: rgb(93 69 145);
} 
#banner-section.product .main-content .right-item .subscribe input::-ms-input-placeholder {
    color: rgb(93 69 145);
}

/* product items */
#product-items{
    padding-top: 5px;
}
#product-items .single-item {
    margin-bottom: 55px;
}
#product-items .single-item .img-area{
    position: relative;
}
#product-items .single-item .img-area p span{
    color: var(--body-color);
}
#product-items .single-item .img-area p{
    position: absolute;
    bottom: -30px;
    right: 30px;
    border-radius: 10px;
    background-color: rgb(128, 70, 248);
    color: var(--body-color);
    padding: 16px 24px;
}
#product-items .single-item .text-area h1{
    color: rgb(90, 62, 142);
    margin: 66px 0 23px 0;
    font-weight: 500;
}
.pagination{
    background-color: #fefeff;
    padding: 10px;
    border: 1px solid #eef1fb;
    border-radius: 10px;
    margin-top: 20px;
}
.pagination li {
    padding: 0 15px;
}
.pagination li a:focus{
    box-shadow: none;
}
.pagination li a {
    font-size: 16px;
    color: rgb(91 85 101);
    line-height: 1.75;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.149);
    box-shadow: 0px 2px 17px 0px rgba(60, 49, 172, 0.1);
    border: none;
    position: relative;
    transition: all 0.3s;
}
.pagination li a::after{
    position: absolute;
    content: '';
    background-color: #e1d9ff;
    width: 1px;
    height: 20px;
    top: 13px;
    right: -15px;
}
.pagination li:last-child a:after{
    display: none;
}
.pagination li a.active,
.pagination li a:hover {
    color: #6930c7;
    font-weight: 500;
    text-decoration: none;
    border-color: var(--body-color);
    background-color: var(--body-color);
    box-shadow: 0px 2px 20px 0px rgba(60, 49, 172, 0.24);
}
.pagination li:last-child a,
.pagination li:first-child a{
    border: none;
    background-color: transparent;
    box-shadow: none;
}
#product-items.products-single {
    padding-top: 0;
}
#product-items.products-single .single-item {
    margin-bottom: -5px;
}
#product-items.products-single .section-header a {
    color: rgb(103 56 199);
    text-decoration: underline;
}
#product-items.products-single .section-header .title {
    font-size: 50px;
    line-height: 60px;
}
#product-items.products-single .section-header {
    margin-bottom: 20px;
}

/* product-single */
#banner-section.product-single .banner-content .text-area{
    margin-top: 4px;
}
#banner-section.product-single .banner-content .text-area h1 {
    margin-bottom: 81px;
}
#banner-product-bottom.product-single{
    background-color: var(--body-color);
    box-shadow: -3.742px 17.607px 46px 0px rgba(70, 31, 227, 0.05);
    padding: 25px 0;
}
#banner-product-bottom.product-single h5{
    font-weight: 400;
    color: rgb(66, 44, 114);
    margin-bottom: 10px;
}
#banner-product-bottom.product-single .left-area .single-item img{
    width: 45px;
    height: 45px;
    margin-right: 5px;
}
#banner-product-bottom.product-single .left-area .single-item{
    position: relative;
    margin-right: 22px;
    padding-right: 28px;
    border-right: 1px solid #c6b9dd;
}
#banner-product-bottom.product-single .left-area .single-item:last-child{
    border-right: none;
    margin-right: 0;
    padding-right: 0;
}
#banner-product-bottom.product-single .right-area .single-item{
    font-size: 16px;
    color: rgb(255, 255, 255);
    line-height: 1.5;
    display: inline-block;
    border-radius: 10px;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 40px;
    margin-left: 20px;
}
#banner-product-bottom.product-single .right-area .single-item.comment img{
    display: block;
}
#banner-product-bottom.product-single .right-area .single-item.comment span{
    color: rgb(88 65 137);
}
#banner-product-bottom.product-single .right-area .single-item.comment{
    background-color: rgb(246, 232, 255);
}
#banner-product-bottom.product-single .right-area .single-item.view{
    background-image: linear-gradient( 45deg, rgb(61,169,245) 0%, rgb(104,61,245) 100%);
}
#banner-product-bottom.product-single .right-area .nice-select{
    padding: 0;
}
#banner-product-bottom.product-single .right-area .nice-select::after{
    display: none;
}
#banner-product-bottom.product-single .right-area .nice-select .list{
    box-shadow: rgb(0 0 0 / 16%) 0px 4px 16px;
    background-color: rgb(255, 255, 255);
    right: 0;
    left: 20px;
    top: 57px;
}
#banner-product-bottom.product-single .right-area .nice-select .list li:last-child{
    border-bottom: none;
}
#banner-product-bottom.product-single .right-area .nice-select .list li{
    border-bottom: 1px solid rgb(229, 232, 235);
    margin: 5px 0;
    padding: 3px 10px;
}
#banner-product-bottom.product-single .right-area .nice-select .list li:hover{
    background-image: linear-gradient(45deg, rgb(215,61,245) 0%, rgb(143,61,245) 100%);
}
#banner-product-bottom.product-single .right-area .nice-select .list li:hover i,
#banner-product-bottom.product-single .right-area .nice-select .list li:hover a{
    color: var(--body-color);
}
#banner-product-bottom.product-single .right-area .nice-select .list li a{
    color: inherit;
}
#banner-product-bottom.product-single .right-area .nice-select .list li i{
    margin-right: 10px;
    font-size: 20px;
}
#banner-product-bottom.product-single .right-area .single-item.share{
    background-image: linear-gradient( 45deg, rgb(215,61,245) 0%, rgb(143,61,245) 100%);
}
#banner-product-bottom.product-single .right-area .single-item.share img{
    margin-right: 15px;
}

/* All Products */
#all-products .tag-list{
    margin: 55px 0 39px 15px;
}
#all-products .tag-list h4{
    font-weight: 500;
    color: #5a3e8e;
    margin-bottom: 15px;
}
#all-products .tag-list .tag-item a{
    background-color: #fbebf2;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: rgb(84 75 103);
    border-radius: 20px;
    padding: 10px 20px;
    margin-right: 15px;
}
#all-products .all-slider{
    margin: 35px 0 96px;
}
#all-products .owl-item{
    display: inline-block;
}
#all-products .owl-nav{
    display: none;
}
#all-products .left-side .highlights {
    margin-bottom: 34px;
}
#all-products .left-side .highlights .items {
    column-count: 2;
    margin-top: 16px;
}
#all-products .left-side .highlights .items p{
    position: relative;
    padding: 20px 0 20px 55px;
    margin-bottom: 20px;
    color: rgb(90 84 117);
    font-weight: 500;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0px 18px 51px 0px rgb(231 226 255 / 67%);
    display: flex;
    align-items: center;
}
#all-products .left-side .highlights .items p::after{
    position: absolute;
    content: '';
    background-image: url(../image/check.png);
    background-repeat: no-repeat;
    height: 17px;
    width: 21px;
    left: 20px;
    transition: all ease 0.3s;
    z-index: 2;
}
#all-products .left-side .highlights .items p::before{
    position: absolute;
    content: '';
    border-radius: 50%;
    background-color: rgb(246, 247, 255);
    box-shadow: 0px 10px 8px 0px rgba(231, 226, 255, 0.65);
    width: 40px;
    height: 40px;
    left: 10px;
    z-index: 0;
}
#all-products .left-side .need-support{
    border: 1px solid #e0d9ff;
    border-radius: 20px;
    padding: 25px 27px;
}
#all-products .left-side .need-support h4{
    color: rgb(78, 58, 118);
    margin-bottom: 12px;
}
#all-products .left-side .title{
    font-size: 50px;
    line-height: 60px;
}
#all-products .left-side .highlights h1,
#all-products .left-side .overview h1{
    color: rgb(90, 62, 142);
    font-weight: 500;
    margin: 29px 0 12px;
}
#all-products .left-side .overview p{
    margin-bottom: 21px;
}
#all-products .right-side h2{
    color: rgb(90, 62, 142);
    font-weight: 500;
}
#all-products .right-side {
    border-radius: 10px;
    background-color: var(--body-color);
    box-shadow: 0px 18px 51px 0px rgb(231 226 255 / 67%);
    padding: 37px 33px 19px;
}
#all-products .right-side p{
    margin: 13px 0 25px;
}
#all-products .input-area {
    margin: 38px 0 38px;
}
#all-products .input-area h5{
    color: rgb(90, 62, 142);
}
#all-products .checkcontainer {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    background-color: #fafaff;
    color: rgb(90, 62, 142);
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #e8e3ff;
    padding: 15px 20px 15px 50px;
    border-radius: 10px;
}
#all-products .checkcontainer:focus-within{
    outline: 1px solid rgb(99, 66, 255);
    border-radius: 10px;
}
#all-products .checkcontainer .currency,
#all-products .checkcontainer .currency span{
    font-size: 18px;
    height: 28px;
}
#all-products .checkcontainer input:checked ~ .radiobtn {
    background-color: #ffffff;
    border: 1px solid #c2bed5;
}
#all-products .radiobtn {
    position: absolute;
    top: initial;
    left: 13px;
    margin: 2px 0 0 5px;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}
#all-products .checkcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
#all-products [type=checkbox], [type=radio] {
    padding: 0;
}
#all-products .checkcontainer input:checked ~ .radiobtn:after {
    display: block;
}
#all-products .checkcontainer .radiobtn:after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #622dcf;
}
#all-products .radiobtn:after {
    content: "";
    position: absolute;
    display: none;
}
#all-products .qtySelector {
    border: 1px solid #b1a0ff;
    border-radius: 10px;
}
#all-products .qtySelector .decreaseQty img,
#all-products .qtySelector .increaseQty img{
    max-width: initial;
    margin-left: -4px;
    margin-top: 3px;
}
#all-products .qtySelector .decreaseQty,
#all-products .qtySelector .increaseQty {
    padding: 10px 11px;
    width: 30px;
    height: 50px;
    float: left;
    cursor: pointer;
    line-height: 18px;
    background-color: #ae93e7;
    border-radius: 10px;
    transition: all 0.3s;
}
#all-products .qtySelector .increaseQty {
    float: right;
}
#all-products .qtySelector .qtyValue {
    border: none;
    padding: 10px;
    margin: 0 5px;
    width: 70px;
    float: left;
    text-align: center;
    background-color: var(--body-color);
    font-size: 18px;
    color: rgb(56, 39, 87);
    line-height: 1.556;
}
#all-products .btn-area a,
#all-products .btn-area .cart{
    color: rgb(255, 255, 255);
    border-radius: 10px;
    background-image: linear-gradient( 45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    box-shadow: 0px 26px 38px 0px rgba(254, 81, 149, 0.3);
    width: 100%;
    padding: 12px 0;
    margin-bottom: 20px;
    text-align: center;
}
#all-products .btn-area .buy{
    background-image: linear-gradient( 45deg, rgb(215,61,245) 0%, rgb(143,61,245) 100%);
    box-shadow: unset;
}
#all-products .right-bottom h5 img{
    margin-right: 10px;
}
#all-products .right-bottom h5 {
    background-color: #f4f3ff;
    text-align: center;
    padding: 10px;
    margin-top: 40px;
    color: rgb(98, 45, 207);
    border-radius: 10px;
}

/* cart page */
#banner-section.inner-banner.cart .text-area{
    margin-bottom: 129px;
}
#banner-section.inner-banner.about .text-area {
    margin-bottom: 59px;
    margin-top: 3px;
}
#banner-section.cart .breadcrumb{
    background: transparent;
    align-items: center;
    padding-left: 0;
}
#banner-section.cart .breadcrumb li a,
#banner-section.cart .breadcrumb li {
    font-size: 16px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
}
#banner-section.cart .breadcrumb .active{
    color: rgb(255, 255, 255);
}
#banner-section.cart .breadcrumb-item+.breadcrumb-item::before {
    content: url('../image/right-icon.png');
    margin-top: 2px;
}
.slick-slide>div:nth-child(1){
    /* margin:0 10px 0  */
    margin-right: 10px;
    margin-left: 10px;
}
.slick-list{
    margin:0 -10px
}
/* .auto_slider{
    display: flex;
    justify-content:space-between !important ;
}
.auto_slider .slider_body{
    margin-right: 8px !important;
}
.dotsClass{
    visibility: visible;
    width: 400px;
} */
/* cart page */
#cart-products .table-responsive {
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 18.85px -58.014px 46px 0px rgb(70 31 227 / 8%);
    margin-top: -70px;
    padding: 33px 40px 0;
}
#cart-products .qtySelector {
    border: 1px solid #b1a0ff;
    border-radius: 10px;
}
#cart-products .qtySelector .decreaseQty img,
#cart-products .qtySelector .increaseQty img{
    max-width: initial;
    margin-left: -4px;
    margin-top: 3px;
}
#cart-products .qtySelector .decreaseQty,
#cart-products .qtySelector .increaseQty {
    padding: 10px 11px;
    width: 30px;
    height: 50px;
    float: left;
    cursor: pointer;
    line-height: 24px;
    background-color: #ae93e7;
    border-radius: 10px;
    transition: all 0.3s;
}
#cart-products .qtySelector .increaseQty {
    float: right;
}
#cart-products .qtySelector .qtyValue {
    border: none;
    padding: 10px;
    margin: 0 5px;
    width: 70px;
    float: left;
    text-align: center;
    background-color: var(--body-color);
    font-size: 18px;
    color: rgb(56, 39, 87);
    line-height: 1.556;
}
#cart-products .table thead tr th{
    color: rgb(66, 43, 113);
    font-weight: 500;
    text-align: center;
    width: 10%;
}
#cart-products .table tbody tr {
    border: 1px solid #d0c0f1;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #d0c0f1;
}
#cart-products .table tbody tr td{
    color: rgb(90, 62, 142);
    font-weight: 500;
    text-align: center;
}
#cart-products .table th,
#cart-products .table td {
    vertical-align: middle;
    border-top: none;
    border-radius: 20px 0 0 20px;
}
#cart-products .table thead th{
    border: none;
}
#cart-products .table tbody tr td {
    position: relative;
    border-radius: 0 20px 20px 0;
}
#cart-products .table tbody tr td button{
    position: absolute;
    content: url(../image/close-btn.png);
    right: -9px;
    top: -9px;
    border-radius: 50%;
}
#cart-products .coupon-cart {
    margin-left: 5%;
}
#cart-products .coupon-cart form {
    margin-right: 30px;
}
#cart-products .coupon-cart form input{
    border-radius: 25px;
    border: 1px solid #d0c0f1;
}
#cart-products .coupon-cart form .input-area{
    margin-right: 30px;
}
#cart-products .coupon-cart form .cmn-btn{
    width: 160px;
    padding: 11px 20px;
}
#cart-products .coupon-cart .cmn-btn{
    text-transform: capitalize;
}
#cart-products .coupon-cart .cart{
    background-image: linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(235,231,244) 100%);
    box-shadow: 0px 14px 46px 0px rgba(98, 45, 207, 0.1);
    color: rgb(76, 73, 128);
    text-transform: capitalize;
}
#cart-products .cart-bottom h3{
    border-bottom: 1px solid #c8c0d7;
    margin-bottom: 19px;
    padding-bottom: 18px;
}
#cart-products .cart-bottom h3 .left-side{
    width: 18%;
}
#cart-products table,
#cart-products th,
#cart-products td {
    background: #ffffff;
    padding: 5px;
}
#cart-products table {
    background: #999999;
    border-spacing: 15px;
    background: transparent;
    border-spacing: 0 20px;
    border-collapse: separate;
}
#cart-products.cart .cart-bottom {
    margin-top: -15px;
    margin-bottom: -10px;
}
#cart-products.cart .cart-bottom h1{
    color: rgb(90, 62, 142);
    margin-bottom: 42px;
    font-weight: 500;
}
#cart-products.cart .cart-bottom .cmn-btn{
    margin-top: 19px;
}

/* blog content */
.breadcrumb-area{
    width: 70%;
    color: white;
    margin-top: 30px;
    margin-bottom: 70px;
}
#blog-content .nav-top-area{
    box-shadow: 0px 14px 46px 0px rgb(98 45 207 / 10%);
    border-radius: 20px;
    padding: 45px 115px;
    background-color: var(--body-color);
    margin-top: -70px;
    margin-bottom: 60px;
    position: relative;
}
#blog-content .nav-top-area .nav-tabs{
    border: none;
    
}
#blog-content .nav-top-area .nav-tabs .nav-link {
    border-radius: 10px;
    background-image: linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(235,231,244) 100%);
    box-shadow: 0px 14px 46px 0px rgba(98, 45, 207, 0.1);
    color: rgb(76, 73, 128);
    font-size: 16px;
    font-weight: 500;
}
#blog-content .nav-top-area .nav-tabs .nav-link img{
    margin-right: 5px;
}
#blog-content .nav-top-area .nav-tabs .nav-link.active img{
    opacity: 0;
}
#blog-content .nav-top-area .nav-tabs .nav-link.active{
    position: relative;
}
#blog-content .nav-top-area .nav-tabs .nav-link.active:before{
    width: 100%;
    content: "";
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
}
/* #blog-content .nav-top-area .nav-tabs .nav-link.posts.active:before{
    background-image: url(../image/post-alt.png);
} */
#blog-content .nav-top-area .nav-tabs .nav-link.design.active:before{
    background-image: url(../image/design-alt.png);
}
#blog-content .nav-top-area .nav-tabs .nav-link.marketing.active:before{
    background-image: url(../image/marketing-alt.png);
}
#blog-content .nav-top-area .nav-tabs .nav-link.development.active:before{
    background-image: url(../image/dev-alt.png);
}
#blog-content .nav-top-area .nav-tabs .nav-link.tutorial.active:before{
    background-image: url(../image/tutorial-alt.png);
}
#blog-content .nav-top-area .nav-tabs .nav-link.active {
    background-image: linear-gradient( 45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    box-shadow: 0px 26px 38px 0px rgba(254, 81, 149, 0.3);
    color: var(--body-color);
}
#blog-content .tab-content .single-item.flex-item{
    display: flex;
}
#blog-content .tab-content .single-item {
    border-radius: 20px;
    background-color: var(--body-color);
    box-shadow: 0px 18px 51px 0px rgba(231, 226, 255, 0.67);
    padding: 10px;
}
#blog-content .tab-content .single-item .text-item{
    padding: 15px 30px;
}
#blog-content .tab-content .single-item .text-item .date-area span{
    color: rgb(128 76 0);
    font-size: 14px;
    font-weight: 500;
}
#blog-content .tab-content .single-item .text-item .date-area .date{
    color: #585761;
}
#blog-content .tab-content .single-item .text-item h3{
    color: #3b368c;
    margin: 10px 0 20px 0;
}
#blog-content .tab-content .single-item .image-area .blog-item{
    max-width: initial;
}
#blog-content .tab-content .single-item .image-area {
    position: relative;
}
#blog-content .tab-content .single-item .image-area span,
#blog-content .tab-content .single-item .image-area .icon{
    position: absolute;
}
#blog-content .tab-content .single-item .image-area span{
    top: 0;
    left: 0;
    border-radius: 5px;
    padding: 0 5px;
    background-color: rgb(98, 45, 207);
    color: var(--body-color);
    margin: 12px;
}
#blog-content .tab-content .single-item.flex-item .image-area .icon{
    bottom: -0;
}
#blog-content .tab-content .single-item .image-area .icon{
    bottom: -30px;
    right: 0;
    border-radius: 50%;
    background-image: linear-gradient(45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    box-shadow: 0px 26px 38px 0px rgb(254 81 149 / 30%);
    padding: 10px;
    margin: 10px;
}
#blog-content .right-area {
    border-radius: 10px;
    background-color: var(--body-color);
    box-shadow: 0px 18px 51px 0px rgb(231 226 255 / 67%);
    padding: 36px 20px 29px;
}
#blog-content .right-area h3{
    margin-bottom: 18px;
    color: rgb(59, 54, 140);
}
#blog-content .right-area .subscribe {
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #cebef0;
    background-color: #f6f6ff;
}
#blog-content .right-area .subscribe input {
    background-color: #f6f6ff;
    border: none;
    color: rgb(93 69 145);
    border-radius: 10px;
}
#blog-content .right-area .subscribe input::placeholder {
    color: rgb(93 69 145);
    opacity: 1;
}  
#blog-content .right-area .subscribe input:-ms-input-placeholder {
    color: rgb(93 69 145);
} 
#blog-content .right-area .subscribe input::-ms-input-placeholder {
    color: rgb(93 69 145);
}
#blog-content .right-area button {
    background-image: linear-gradient(45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    box-shadow: 0px 26px 38px 0px rgb(254 81 149 / 30%);
    border-radius: 10px;
    padding: 0 30px;
    margin: 3px 5px;
}
#blog-content .featured-post{
    margin-top: 45px;
}
#blog-content .featured-post h3{
    margin-bottom: 13px;
    color: rgb(59, 54, 140);
}
#blog-content .single-item{
    padding: 5px;
    margin-bottom: 15px;
    transition: all 0.3s;
}
#blog-content .single-item:hover{
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 18px 51px 0px rgba(231, 226, 255, 0.67);
}
#blog-content .single-item .left-item img{
    max-width: initial;
    margin-right: 15px;
}
#blog-content .single-item .right-item h5{
    font-size: 14px;
    color: rgb(128 76 0);
}
#blog-content .single-item .right-item p{
    color: #422c72;
    font-weight: 500;
}
#blog-content .social-area{
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 18px 51px 0px rgb(231 226 255 / 67%);
    padding: 30px;
    margin-top: 50px;
}
#blog-content .social-area h3{
    color: rgb(59, 54, 140);
    margin-bottom: 19px;
}
#blog-content .social a{
    width: 40px;
    height: 40px;
    border: 1px solid #e8eaef;
    border-radius: 50%;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background-image: none;
    box-shadow: unset;
}
#blog-content .social a.active,
#blog-content .social a:hover{
    background-image: linear-gradient(45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    box-shadow: -2.099px 10.798px 13px 0px rgba(254, 81, 149, 0.3);
}
#blog-content .social a.active i,
#blog-content .social a:hover i{
    color: var(--body-color);
}
#blog-content .social a i{
    color: #9794c2;
}
#blog-content .tags-area h3 {
    color: rgb(59, 54, 140);
    margin-bottom: 26px;
}
#blog-content .tags-area {
    margin-top: 42px;
}
#blog-content .tags-area .tags .tag-item{
    background-color: rgba(236, 235, 255, 0.502);
    color: rgb(81 78 120);
    padding: 8px 15px;
    /* margin-right: 12px; */
    margin-bottom: 12px;
    font-weight: 500;
    border-radius: 20px;
    font-size: 16px;
}
#blog-content .tags-area .tags .tag-item.active,
#blog-content .tags-area .tags .tag-item:hover{
    background-image: linear-gradient( 45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    box-shadow: -2.099px 10.798px 13px 0px rgba(254, 81, 149, 0.3);
    color: var(--body-color);
}
#blog-content .view-product{
    position: relative;
    margin-top: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#blog-content .view-product .text-area{
    position: absolute;
    top: 0;
    text-align: center;
    padding: 60px 60px 25px;
}
#blog-content .view-product .text-area h1{
    margin: 26px 0 27px;
    color: var(--body-color);
}
#blog-content .pagination {
    margin-top: 44px;
    margin-bottom: -2px;
}

/* blog single */
#banner-section.blog-single {
    padding-bottom: 130px;
}
#banner-section.blog-single .head-bottom{
    margin: 6px 0 30px;
}
#banner-section.blog-single .date-area span{
    font-size: 14px;
    color: rgb(200, 197, 231);
}
#banner-section.blog-single .date-area .d-title{
    color: var(--body-color);
}
#banner-section.blog-single .social-area h3{
    margin-right: 30px;
}
#banner-section.blog-single .social a{
    width: 40px;
    height: 40px;
    border: 1px solid #8758d8;
    border-radius: 50%;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -5px;
    background-image: unset;
    box-shadow: none;
}
#banner-section.blog-single .social a.active,
#banner-section.blog-single .social a:hover{
    background-image: linear-gradient(45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    box-shadow: -2.099px 10.798px 13px 0px rgba(254, 81, 149, 0.3);
}
#banner-section.blog-single .social a.active i,
#banner-section.blog-single .social a:hover i{
    color: var(--body-color);
}
#banner-section.blog-single .social a i{
    color: #d9d7ff;
}
#single-blog.blog-single {
    margin-top: -130px;
}
#single-blog.blog-single .top-img{
    position: relative;
    background-color: var(--body-color);
    border-radius: 20px;
    box-shadow: 10.573px -45.795px 46px 0px rgba(70, 31, 227, 0.05);
    margin-bottom: 70px;
}
#single-blog.blog-single .top-img img{
    padding: 20px;
}
#single-blog.blog-single .top-img .icon-area{
    position: absolute;
    bottom: -15px;
    right: 60px;
    border-radius: 50%;
    background-image: linear-gradient(45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#single-blog .single-step {
    margin-bottom: 53px;
}
#single-blog .single-step h1 {
    font-size: 36px;
    color: rgb(59, 54, 140);
    font-weight: bold;
    line-height: 1.278;
    margin-bottom: 12px;
}
#single-blog .single-step p{
    margin-bottom: 21px;
}
#single-blog .single-step ul li{
    margin-bottom: 11px;
}
#single-blog .single-step h2 {
    color: rgb(59, 54, 140);
    font-weight: bold;
    margin-bottom: 9px;
}
#single-blog .quote-step{
    border-radius: 10px;
    background-color: rgb(250, 250, 255);
}
#single-blog .quote-step {
    text-align: center;
    padding: 60px 120px;
    margin-bottom: 56px;
}
#single-blog .quote-step h3{
    font-size: 26px;
    color: rgb(59, 54, 140);
    line-height: 1.538;
    margin-bottom: 18px;
}
#single-blog .quote-step h4 {
    font-size: 20px;
    color: rgb(32 130 83);
    line-height: 1.5;
}
#single-blog .about-me{
    border-radius: 20px;
    background-color: var(--body-color);
    box-shadow: 2.3px 21.879px 79px 0px rgb(70 31 227 / 10%);
    padding: 40px 40px 54px;
}
#single-blog .about-me .img-area img{
    max-width: initial;
}
#single-blog .about-me .section-header {
    margin-left: 6%;
    margin-bottom: 0;
}
#single-blog .about-me h1{
    color: rgb(49, 21, 105);
    font-weight: 500;
}
#single-blog .about-me p{
    margin: 10px 0 35px;
}

/* latest articles */
#latest-articles .overlay{
    margin-bottom: -32px;
}
#latest-articles .single-item {
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 18px 51px 0px rgba(231, 226, 255, 0.67);
    padding: 10px;
}
#latest-articles .single-item .image-area {
    position: relative;
}
#latest-articles .single-item .image-area span,
#latest-articles .single-item .image-area .icon{
    position: absolute;
}
#latest-articles .single-item .image-area span{
    top: 0;
    left: 0;
    border-radius: 5px;
    padding: 0 5px;
    background-color: rgb(98, 45, 207);
    color: var(--body-color);
    margin: 12px;
}
#latest-articles .single-item.flex-item .image-area .icon{
    bottom: -0;
}
#latest-articles .single-item .image-area .icon{
    bottom: -30px;
    right: 0;
    border-radius: 50%;
    background-image: linear-gradient(45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    box-shadow: 0px 26px 38px 0px rgb(254 81 149 / 30%);
    padding: 10px;
    margin: 10px;
}
#latest-articles .section-header {
    margin-top: -6px;
    margin-bottom: 39px;
}
#latest-articles .section-header .title{
    font-size: 50px;
    color: rgb(90, 62, 142);
    font-weight: bold;
    line-height: 1.2;
}
#latest-articles .section-header a{
    color: rgb(103 56 199);
    text-decoration: underline;
}
#latest-articles .single-item .text-item{
    padding: 15px 30px;
}
#latest-articles .single-item .text-item .date-area span{
    color: rgb(128 76 0);
    font-size: 14px;
    font-weight: 500;
}
#latest-articles .single-item .text-item .date-area .date{
    color: #585761;
}
#latest-articles .single-item .text-item h3{
    color: #3b368c;
    margin: 10px 0 20px 0;
}

/* Pricing Plan */
#pricing-section {
    background-color: #fafbff;
}
#pricing-section .single-plan{
    padding: 30px 16px;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 10%);
    border-radius: 30px;
    background-color: var(--body-color);
}
#pricing-section .single-plan .introd-plan {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-top: 20px;
}
#pricing-section .single-plan .top-plan h4{
    color: rgb(49, 21, 102);
}
#pricing-section .single-plan .introd-plan strong,
#pricing-section .single-plan .introd-plan span{
    color: rgb(23 95 3);
}
#pricing-section .single-plan .introd-plan .plan-current {
    margin: 0 18px;
    font-size: 60px;
}
#pricing-section .single-plan .introd-plan .plan-del{
    font-size: 28px;
    text-decoration: line-through;
    color: #ccc;
}
#pricing-section .single-plan .plan-list{
    background-color: #fafbff;
    padding: 15px 5px;
    border-radius: 20px;
    text-align: start;
}
#pricing-section .single-plan .plan-list p{
    margin: 10px 0;
}
#pricing-section .single-plan .plan-list i{
    margin-right: 5px;
}
#pricing-section .single-plan .btn-area{
    margin-top: 30px;
}

/* Advantages Block */
#advantages-block .single-item {
    text-align: center;
    margin-bottom: -8px;
}
#advantages-block .single-item img{
    width: 130px;
    
}
#advantages-block .single-item h4{
    color: rgb(49, 21, 102);
    margin: 25px 0 20px 0;
}

/* features table */
#features-table {
    background-color: #fafbff;
}
#features-table .table{
    width: 99%;
    border: 1px solid #d0c0f1;
    margin-bottom: 0;
}
#features-table .table thead tr th{
    color: rgb(66, 43, 113);
    font-weight: bold;
    text-align: center;
    width: 10%;
}
#features-table .table tbody tr {
    border-top: 1px solid #d0c0f1;
    border-bottom: 1px solid #d0c0f1;
    padding: 20px;
    border-radius: 10px;
}
#features-table .table tbody tr td{
    color: rgb(90, 62, 142);
    font-weight: 500;
    text-align: center;
}
#features-table .table tbody tr td i{
    color: red;
    font-size: 24px;
}
#features-table .table tbody tr td i.fa-check{
    color: green;
}
#features-table .table th,
#features-table .table td {
    vertical-align: middle;
    border-top: none;
    border-radius: 20px 0 0 20px;
}
#features-table .table thead th{
    border: none;
}
#features-table .table tbody tr td {
    position: relative;
    border-radius: 0 20px 20px 0;
}








/* Contact me */
#banner-section.inner-banner.contact .text-area {
    margin-bottom: 43px;
}
#contact-me .contact-info-area{
    margin-top: -120px;
    position: relative;
}
#contact-me .contact-info-area .contact-bg{
    border-radius: 20px;
    background-color: var(--body-color);
    box-shadow: 4.358px -49.81px 46px 0px rgba(70, 31, 227, 0.08);
}
#contact-me .pr-15{
    padding-right: 15px !important;
}
#contact-me .pl-15{
    padding-left: 15px !important;
}
#contact-me .section-header .sub-title{
    font-size: 28px;
    font-weight: bold;
    line-height: 1.357;
}
#contact-me .section-header .title{
    font-size: 36px;
    color: rgb(59, 24, 139);
    font-weight: bold;
    line-height: 1.278;
}
#contact-me .contact-info a{
    color: rgb(59, 24, 139);
    margin-bottom: 45px;
}
#contact-me .contact-info a img{
    margin-right: 11px;
}
#contact-me .social-area h4{
    margin-bottom: 19px;
}
#contact-me .contact-info-area .contact-bg .cmn-btn{
    display: block;
}
#contact-me .form-area .input-area.select{
    border: none;
}
#contact-me .form-area .input-area {
    position: relative;
    border: 1px solid #efeafa;
    background-color: #fbfbff;
    border-radius: 30px;
    margin-bottom: 30px;
    width:50vw
}
#contact-me .form-area input{
    position: relative;
    padding: 15px 30px;
    border-radius: 30px;
    background-color: #fbfbff;
    color: rgb(59, 24, 139);
}
#contact-me .form-area textarea{
    position: relative;
    padding: 15px 30px;
    border-radius: 30px;
    background-color: #fbfbff;
    color: rgb(59, 24, 139);
}
#contact-me .form-area .input-area img {
    position: absolute;
    right: 30px;
    top: 16px;
}
#contact-me .form-area .nice-select{
    border: 1px solid #efeafa;
    border-radius: 30px;
    width: 100%;
    background-color: #fbfbff;
    color: rgb(136, 123, 167);
    padding: 9px 30px;
    margin-bottom: 30px;
    height: initial;
}
#contact-me .form-area .nice-select.open .list {
    width: 100%;
}
#contact-me .form-area .nice-select span{
    color: rgb(59, 24, 139);
}
#contact-me .form-area .nice-select:after {
    border-bottom: 2px solid #958ab1;
    border-right: 2px solid #958ab1;
    height: 12px;
    margin-top: -8px;
    right: 36px;
    width: 12px;
}
#contact-me input,
#contact-me textarea {
    border: none;
}
#contact-me .textarea-area textarea{
    border: 1px solid #efeafa;
    background-color: #fbfbff;
    border-radius: 30px;
    margin-bottom: 30px;
    color: rgb(59, 24, 139)!important;
}
#contact-me .contact-info-area .contact-area {
    border-radius: 100px;
    background-color: rgb(251, 251, 255);
    padding:60px;
}
#contact-me .contact-info-area .contact-area .contact-header{
    margin-bottom: 32px;
}
#contact-me .contact-info-area .contact-area .contact-header u{
    font-weight: 600;
}

/* responsive area */
@media (min-width: 991px) and (max-width: 1449.98px) {
    #banner-section .right-area .right-img {
        width: 100% !important;
    }
    #banner-section .text-right {
        text-align: center !important;
    }
    #now-your-turn .right-area {
        right: 0;
    }
    #footer-section .overlay{
        margin: 0 2%;
    }
}
@media (min-width: 992px) {
    #header-section .navbar .navbar-nav li .cart-menu button {
        right: -25px;
    }
}

@media (min-width: 1449px) {
    #banner-bottom .left-area {
        transform: translateY(-350px);
    }
}

@media (min-width: 1200px) and (max-width: 1324.98px) { 
    #banner-bottom .left-area {
        transform: translateY(-200px);
    }
    #now-your-turn .right-area {
        right: -2%;
        top: -5%;
    }
}

@media(max-width: 1199px) {
    h2{
        font-size: 24px;
        line-height: 34px;
    }
    h3{
        font-size: 18px;
        line-height: 28px;
    }
    #specilizing-section .single-item {
        padding: 40px 18px 47px;
    }
    #now-your-turn .right-area {
        right: -14%;
        top: 20%;
    }
    #now-your-turn .right-area img{
        max-width: 80%;
    }
    #my-story-section .social {
        bottom: 95px;
    }
    #testimonials .slick-active .left-area img {
        transform: translate(0, -60px);
    }
    #my-story-section .right-area .user {
        transform: translate(-120px, 60px);
    }
    #my-story-section.pt-120{
        padding-top: 0;
    }
    #banner-bottom.portfolio-details .single-item::after {
        right: -45px;
    }
    .navbar-collapsed .main-menu li a {
        padding: 40px 20px;
    }
    #banner-product-bottom.product-single .left-area .single-item {
        margin-right: 12px;
        padding-right: 12px;
    }
    #banner-product-bottom.product-single .left-area .single-item img{
        margin-right: 0;
    }
    #all-products .right-side {
        padding: 37px 10px 19px;
    }
    #all-products .left-side .highlights .items p{
        font-size: 16px;
    }
    #blog-content .nav-top-area {
        padding: 45px 60px;
    }
    #blog-content .right-area .subscribe input {
        padding: 10px 10px;
    }
    #blog-content .tab-content .single-item .image-area .blog-item{
        max-width: 100%;
    }
    #blog-content .tab-content .single-item.flex-item .image-area .blog-item{
        max-width: initial;
    }
    #blog-content .view-product .text-area h1 {
        margin: 15px 0;
        color: var(--body-color);
        font-size: 24px;
        line-height: 34px;
    }
    #blog-content .tab-content .single-item .text-item {
        padding: 15px 20px;
    }
    #single-blog .about-me .social-area{
        display: grid !important;
    }
    #single-blog .about-me .social-area h4{
        margin-bottom: 30px;
    }
}

@media (max-width: 991px) {
    h1, h1>a {
        font-size: 26px;
        line-height: 36px;
    }
    h2{
        font-size: 22px;
        line-height: 32px;
    }
    h3{
        font-size: 18px;
        line-height: 28px;
    }
    p,span,li{
        font-size: 16px;
        line-height: 26px;
    }
    input,textarea{
        font-size: 16px;
        line-height: 26px;
    }
    .title {
        font-size: 40px;
        line-height: 50px;
    }
    .navbar-collapsed .main-menu .cart-area {
        top: 0;
    }
    #header-section .navbar .navbar-nav .dropdown{
        display: grid;
    }
    #banner-section .right-area {
        margin-top: 30px;
    }
    #banner-section .text-area h2 {
        font-size: 60px;
        line-height: 70px;
    }
    #banner-section .banner-content .text-right {
        text-align: center !important;
    }
    .cmn-btn{
        font-size: 14px;
        line-height: 24px;
    }
    .cmn-btn-second {
        font-size: 14px;
        line-height: 24px;
    }
    #header-section .navbar .navbar-nav .cart-area .cart-menu.show {
        left: 0;
        transform: translateY(0px);
    }
    #banner-section .banner-content .main-content .item{
        display: none;
    }
    .navbar-collapsed .main-menu .cart-area button {
        margin: 5px 0;
    }
    #banner-section.inner-banner.cart{
        padding-bottom: 70px !important;
    }
    #banner-section .text-area h3 {
        font-size: 18px;
        line-height: 28px;
    }
    #banner-section .right-area .right-img {
        width: inherit !important;
        max-width: initial;
    }
    #specilizing-section .overlay{
        margin-bottom: -30px;
    }
    #specilizing-section .single-item h2 {
        padding: 0 70px;
    }
    #specilizing-section .single-item {
        margin-bottom: 30px;
    }
    #banner-section.inner-banner.product-single {
        padding-bottom: 70px;
    }
    #now-your-turn .right-area{
        display: none;
    }
    #now-your-turn .bottom-area {
        margin-top: -200px;
    }
    #now-your-turn .bottom-area .single-item {
        margin-top: 150px;
    }
    #contact-me .contact-info-area .contact-area {
        margin-top: 60px;
    }
    #banner-section.inner-banner.portfolio-details {
        padding-bottom: 45px;
    }
    #banner-section.inner-banner.about {
        padding-bottom: 50px;
    }
    #testimonials.portfolio .section-header,
    #testimonials.about .section-header {
        margin-top: 60px;
    }
    #testimonials .slick-initialized .slick-arrow {
        top: 98%;
    }
    #testimonials .slick-initialized .slick-prev {
        left: calc(50% - 55px);
    }
    #testimonials .slick-initialized .slick-next {
        left: calc(60% - 50px);
    }
    #testimonials .text-area {
        margin: 8% 0 12%;
    }
    #testimonials .slick-active .left-area img {
        margin: auto;
    }
    #all-products .all-slider {
        margin: 35px 0 70px;
    }
    #footer-section .overlay {
        margin: 0 2%;
    }
    #banner-section .right-area .right-img {
        max-width: 100%;
    }
    #latest-project .single-item .right-area{
        margin-top: 30px;
    }
    #latest-project .single-item .right-area.mid{
        margin-top: 0;
        margin-bottom: 30px;
    }
    #banner-section {
        padding-top: 200px;
    }
    #banner-section.inner-banner {
        padding-bottom: 70px;
    }
    #banner-bottom .left-area {
        transform: translateY(0px);
        width: 80%;
        margin: 50px auto -40px;
    }
    #banner-bottom {
        height: inherit;
        padding-bottom: 50px;
    }
    #my-story-section .right-area .user {
        transform: translate(-95px, 67px);
        width: 50%;
    }
    #my-story-section .right-area .img-area::after {
        transform: translate(-20px, 45px);
    }
    #product-items.products-single .section-header .title,
    #all-products .left-side .title {
        font-size: 40px !important;
        line-height: 50px !important;
        margin-bottom: 15px;
    }
    #professional-skills .slick-initialized .slick-prev {
        left: calc(88% - 60px);
    }
    #now-your-turn.about .top-area {
        margin-top: -255px;
    }
    #now-your-turn.about .bottom-area {
        margin-top: -165px;
    }
    #banner-section.inner-banner.portfolio {
        padding-top: 200px;
    }
    #banner-section.inner-banner.portfolio .banner-content .text-area h1 {
        display: flex;
        justify-content: center;
    }
    #latest-project.portfolio .overlay .nav-tabs .nav-item {
        margin: 10px 10px;
    }
    #banner-bottom.portfolio form .subscribe button {
        font-size: 14px;
        padding: 0 23px;
    }
    #banner-bottom.portfolio-details .single-item::after {
        right: -15px;
    }
    #project-details .main-content .project-carousel {
        margin-top: 0;
    }
    .pagination {
        margin-top: -20px;
    }
    #banner-section.product .main-content .right-item button {
        padding: 0 15px;
    }
    input, textarea {
        padding: 10px 10px;
    }
    #product-items {
        padding-top: 60px;
    }
    .navbar-collapsed .main-menu li a {
        padding: 10px 8px;
    }
    #banner-section.product-single .banner-content .text-area h1 {
        margin-bottom: 0;
    }
    #banner-product-bottom.product-single .left-area{
        margin-bottom: 40px;
    }
    #product-items.products-single .section-header a {
        width: 105px;
    }
    #product-items.products-single .single-item {
        margin-bottom: 45px;
    }
    #product-items.products-single {
        margin-bottom: -45px;
    }
    #banner-section.inner-banner.cart .text-area {
        margin-bottom: -30px;
    }
    #cart-products .qtySelector .qtyValue {
        width: 38px;
    }
    #cart-products .table-responsive {
        padding: 33px 25px 0;
    }
    #cart-products .coupon-cart input {
        padding: 10px 30px;
    }
    #cart-products.cart .cart-bottom h1 {
        margin-bottom: 24px;
    }
    #cart-products .table thead tr th {
        padding: 0 50px;
    }
    #banner-section.cart .breadcrumb-item+.breadcrumb-item {
        margin-top: 0;
    }
    #banner-section.cart .breadcrumb {
        padding-left: 0;
    }
    #blog-content .nav-top-area .nav-tabs .nav-link {
        padding: 10px 8px;
    }
    #blog-content .nav-top-area {
        padding: 40px 10px;
        margin-bottom: 40px;
    }
    #blog-content .right-area {
        margin-top: 60px;
    }
    #blog-content .view-product .text-area h1 {
        margin: 60px 0;
    }
    #blog-content .nav-top-area .nav-tabs .nav-link {
        padding: 10px 30px;
        margin: 10px 10px;
    }
    #blog-content .nav-top-area .nav-tabs {
        justify-content: center !important;
    }
    #blog-content .tab-content .single-item.flex-item {
        display: grid;
    }
    #single-blog.blog-single .top-img {
        margin-top: 0;
    }
    #banner-section.blog-single .date-area {
        margin-bottom: 40px;
    }
    #banner-section.inner-banner {
        padding-bottom: 150px;
    }
    #single-blog.blog-single .top-img {
        margin-bottom: 35px;
    }
    #single-blog .single-step {
        margin-bottom: 30px;
    }
    #single-blog .quote-step {
        padding: 60px 50px;
    }
    #latest-articles .section-header {
        margin-bottom: 30px;
    }
    #latest-articles .section-header .title {
        font-size: 36px;
        line-height: 46px;
    }
    #single-blog .about-me{
        display: grid !important;
        text-align: center;
    }
    #single-blog .about-me .section-header {
        margin-left: 0;
        margin-top: 30px;
        margin-bottom: 0;
    }
    #single-blog .about-me .social-area .social{
        justify-content: center;
    }
    #single-blog .about-me .social-area h4 {
        margin-right: 0;
    }
    #latest-articles.blog-single .section-header .title {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 0;
        padding: 0;
    }
    #latest-articles .single-item .image-area img {
        width: 100%;
        height: fit-content;
    }
    #latest-articles .single-item .text-item {
        padding: 0 10px 20px;
    }
    #latest-articles .social-area .social a {
        margin: 0 10px;
    }
    #banner-section.inner-banner.blog {
        padding-bottom: 75px;
    }
    #pricing-section .single-plan {
        margin-bottom: 30px;
    }
    #pricing-section .m-cus {
        margin-bottom: -30px;
    }
    #advantages-block .m-cus{
        margin-bottom: -30px;
    }
    #advantages-block .single-item{
        margin-bottom: 30px;
    }
    #contact-me .form-area .textarea-area textarea {
        padding: 10px 30px;
    }
    #banner-section.inner-banner.contact .text-area {
        margin-bottom: 10px;
    }
    #advantages-block .single-item h4 {
        margin: 15px 0;
    }
    #advantages-block .single-item img {
        width: 100px;
    }
    #contact-me .contact-info{
        display: grid;
    }
}

@media (max-width: 767px) {
    .section-header{
        margin-bottom: 40px;
    }
    .sub-title,
    .sub-title-alt{
        font-size: 16px;
    }
    #banner-section .text-area h5 {
        font-size: 16px;
        margin-bottom: 10px;
    }
    .title{
        font-size: 40px;
        padding: 10px 0 13px 0;
    }
    .area-title{
        font-size: 16px;
    }
    .justify-cen{
        -ms-flex-pack: center!important;
        justify-content: center!important;
    }
    .right-btn {
        right: 25%;
    }
    .navbar-light .navbar-toggler {
        right: 16%;
    }
    #banner-section {
        padding-top: 130px;
    }
    #footer-section .footer-bottom .main-content .left-area p a {
        font-size: 14px;
        line-height: 24px;
    }
    a {
        font-size: 14px;
        line-height: 24px;
    }
    #footer-section .bottom-area ul li {
        margin-left: 30px;
    }
    #footer-section .overlay .section-header .cmn-btn {
        margin-top: 0;
    }
    #faq-section .overlay .card-header .btn-link {
        font-size: 18px;
        line-height: 28px;
    }
    #faq-section .overlay .collapsing .card-body,
    #faq-section .overlay .collapse .card-body{
        font-size: 16px;
        line-height: 26px;
    }
    #my-story-section .social-area {
        display: none;
    }
    #banner-section {
        background-size: 150% 100%;
    }
    #professional-skills{
        margin-top: 610px;
    }
    #professional-skills .slick-initialized .slick-arrow{
        display: none !important;
    }
    #my-story-section .right-area .user {
        transform: translate(-120px, 77px);
        width: initial;
    }
    #banner-section.inner-banner .banner-content .text-area h1 {
        font-size: 45px;
        line-height: 55px;
    }
    #banner-bottom .left-area .download {
        transform: translateY(-85px);
    }
    #banner-bottom .left-area .download {
        font-size: 18px;
        padding: 8px 20px;
        line-height: 28px;
        transform: translateY(-85px);
    }
    #past-experience .single-item {
        border-radius: 20px;
        padding: 30px;
        margin: 15px 0;
    }
    #now-your-turn.about .top-area h1 {
        font-size: 24px;
        line-height: 34px;
    }
    #now-your-turn.about .top-area {
        margin-top: -210px;
        width: 300px;
        height: 300px;
        padding: 0 30px;
    }
    #past-experience .scroll-line::after,
    #past-experience .scroll-line::before {
        top: calc(30% - 100px);
    }
    #footer-section .footer-bottom .main-content .left-area p{
        text-align: center;
        margin-bottom: 15px;
    }
    #banner-bottom.portfolio .left-icon{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    #now-your-turn.portfolio {
        margin-top: 150px;
    }
    #contact-me .contact-info-area .contact-area {
        padding: 20px;
    }
    #banner-section.inner-banner.portfolio-details {
        padding-bottom: 30px;
    }
    #banner-section.portfolio-details .right-side {
        margin-top: 45px;
    }
    #banner-bottom.portfolio-details .items {
        margin: 0;
        display: grid !important;
        justify-content: inherit !important;
        text-align: center;
    }
    #banner-bottom.portfolio-details .single-item {
        margin-bottom: 40px;
    }
    #banner-bottom.portfolio-details .single-item:last-child {
        margin-bottom: 0;
    }
    #banner-bottom.portfolio-details .single-item::after {
        display: none;
    }
    #project-details .main-content img {
        margin-bottom: 30px;
    }
    #project-details .main-content .project-carousel h1 {
        margin-bottom: 26px;
        font-size: 26px;
        line-height: 34px;
    }
    #banner-section.product .banner-content .text-area h1 {
        margin-bottom: 45px;
    }
    #banner-section.product .main-content .right-item{
        margin-top: 35px;
    }
    #banner-section.product .main-content {
        margin-bottom: -110px;
    }
    #product-items {
        padding-top: 100px;
    }
    #all-products .left-side .title {
        font-size: 40px;
        line-height: 50px;
    }
    #product-items.products-single .section-header .title {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 0;
        padding: 0;
    }
    #all-products .left-side .title {
        margin-bottom: -25px;
    }
    #product-items .single-item .text-area h1 {
        margin: 45px 0 10px 0;
    }
    #all-products .left-side .highlights .items {
        column-count: 1;
    }
    #cart-products .coupon-cart .cart {
        width: 140px;
    }
    #cart-products .coupon-cart form .cmn-btn {
        width: 130px;
        padding: 11px 0;
        text-align: center;
    }
    #cart-products .coupon-cart input {
        padding: 10px 20px;
    }
    #blog-content .tab-content .single-item .image-area {
        text-align: center;
    }
    #blog-content .tab-content .single-item .text-item {
        padding: 15px 0;
    }
    #banner-section .banner-content .social {
        bottom: 30%;
    }
    #banner-section .banner-content .single-icon {
        width: 40px;
        height: 40px;
        margin: 20px 0;
    }
}

@media (max-width: 576px) {
    h1{
        font-size: 24px;
        line-height: 34px;
    }
    h2{
        font-size: 20px;
        line-height: 30px;
    }
    h3,h3>a{
        font-size: 16px;
        line-height: 26px;
    }
    h5,h5>a{
        font-size: 15px;
        line-height: 25px;
    }
    p,span,li{
        font-size: 15px;
        line-height: 25px;
    }
    input,textarea{
        font-size: 14px;
        line-height: 24px;
    }
    .title{
        font-size: 30px;
        line-height: 40px;
    }
    .justify-cen{
        -ms-flex-pack: center!important;
        justify-content: center!important;
    }
    .justify-start{
        -ms-flex-pack: start!important;
        justify-content: start!important;
    }
    .navbar-light .navbar-toggler {
        right: 5%;
    }
    .section-title {
        font-size: 26px;
    }
    .header-area {
        padding: 15px;
    }
    .sub-title {
        margin-bottom: 0;
    }
    .title {
        margin-bottom: 5px;
    }
    #banner-section .banner-content .single-icon {
        width: 35px;
        height: 35px;
        margin: 20px 0;
    }
    #banner-section .banner-content .single-icon img {
        max-width: 60%;
    }
    #banner-section .banner-content .social {
        bottom: 15%;
    }
    #banner-section .right-area .download {
        font-size: 16px;
        padding: 5px 20px;
        transform: translateY(-90px);
    }
    #banner-section .text-area h2 {
        font-size: 40px;
        line-height: 50px;
    }
    #footer-section .bottom-area ul li {
        margin: 20px 6px 0;
    }
    #testimonials .slick-initialized .slick-next {
        left: calc(70% - 50px);
    }
    #testimonials .slick-initialized .slick-prev {
        left: calc(45% - 55px);
    }
    #testimonials .text-area {
        margin: 8% 0 18%;
    }
    #faq-section .overlay .card-header .btn-link {
        font-size: 16px;
        line-height: 26px;
    }
    .cmn-btn {
        font-size: 12px;
        line-height: 22px;
    }
    #banner-section .text-area .btn-area .cmn-btn {
        margin-right: 15px;
    }
    #product-items.products-single .section-header .title, #all-products .left-side .title {
        font-size: 30px !important;
        line-height: 40px !important;
    }
    .btn-icon {
        width: 30px;
    }
    .cmn-btn-second:hover .btn-icon {
        width: 40px;
    }
    #specilizing-section .single-item h2 {
        padding: 0 50px;
    }
    #all-products .all-slider {
        margin: 35px 0 50px;
    }
    #now-your-turn .bottom-area .single-item span {
        font-size: 60px;
    }
    #now-your-turn .bottom-area .single-item {
        padding: 50px 0 50px;
    }
    #banner-section.inner-banner .social-area {
        justify-content: center !important;
        margin-top: 20px;
    }
    #features-table .table {
        width: 147%;
    }
    #past-experience .scroll-line .row{
        display: flex;
        justify-content: center;
    }
    #past-experience .scroll-line .left-point-none,
    #past-experience .left-point,
    #past-experience .scroll-line::after,
    #past-experience .scroll-line::before{
        display: none !important;
    }
    #banner-section.inner-banner.portfolio-details .banner-content .text-area{
        text-align: center;
    }
    #banner-section.product .main-content .left-item .nice-select {
        margin: 0 30px;
    }
    #all-products .tag-list .tag-item a {
        padding: 10px 10px;
    }
    #contact-me .contact-info-area .contact-area {
        border-radius: 50px;
    }
    #blog-content .tab-content .single-item .image-area .blog-item {
        width: 100%;
    }
    #single-blog .quote-step h3 {
        font-size: 20px;
        line-height: 30px;
    }
    #single-blog .quote-step {
        padding: 30px 20px;
    }
}

@media (max-width: 480px) {
    h1{
        font-size: 20px;
        line-height: 30px;
    }
    #professional-skills {
        margin-top: 125%;
    }
    #my-story-section .right-area .user {
        transform: translate(-122px, 91px);
        width: 50%;
    }
    #my-story-section .right-area .img-area {
        left: 5%;
    }
    #banner-bottom .left-area {
        width: 90%;
    }
    #banner-section.inner-banner .banner-content .text-area h1 {
        font-size: 30px;
        line-height: 40px;
    }
    #banner-section.inner-banner .banner-content .social-area h3 {
        margin-bottom: 0;
        margin-top: 15px;
    }
    #banner-section.inner-banner .banner-content .single-icon {
        margin: 20px 0 15px 15px;
    }
    #now-your-turn.about .top-area {
        margin-top: -180px;
        width: 260px;
        height: 260px;
        padding: 0 30px;
    }
    #banner-section .text-area .cmn-btn {
        padding: 12px 18px;
    }
    #banner-section.product .main-content .left-item .nice-select {
        margin: 0 5px;
    }
    #banner-product-bottom.product-single .left-area .single-item {
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 0;
        border-right: none;
        margin-bottom: 25px;
    }
    #banner-product-bottom.product-single .right-area{
        display: grid !important;
        margin-bottom: 30px;
    }
    #banner-product-bottom.product-single .right-area .single-item {
        margin-bottom: 25px;
        justify-content: center;
    }
    #all-products .tag-list .tag-item {
        display: grid !important;
    }
    #all-products .tag-list .tag-item a {
        text-align: center;
        margin-bottom: 15px;
    }
    #all-products .left-side .title {
        font-size: 28px;
        line-height: 38px;
    }
    #product-items.products-single .section-header .title {
        font-size: 30px;
        line-height: 40px;
    }
    #product-items.products-single .section-header a {
        width: 100px;
    }
    #banner-section.blog-single .date-area {
        margin-bottom: 20px;
    }
    #cart-products .coupon-cart{
        display: grid !important;
    }
    #cart-products .coupon-cart form input {
        width: 140px;
    }
    #cart-products .coupon-cart form .input-area {
        margin-right: 15px;
    }
    #cart-products .coupon-cart {
        margin-top: 20px;
    }
    #cart-products .cart-bottom h3 .left-side {
        width: 35%;
    }
    #cart-products .table-responsive {
        padding: 25px 0 0;
    }
    #cart-products .coupon-cart {
        margin-left: 0;
    }
    #features-table .table {
        width: 215%;
    }
    #blog-content .view-product .text-area h1 {
        margin: 10px 0;
    }
    #single-blog.blog-single .social-area .social a {
        margin: 0 10px;
    }
    #banner-section.inner-banner .social-area {
        display: grid !important;
    }
    #banner-section.inner-banner .banner-content .social-area h3 {
        margin-bottom: 20px;
    }
    #single-blog.blog-single .top-img img {
        padding: 5px;
    }
    #single-blog.blog-single .top-img .icon-area img{
        width: 45px;
    }
    #single-blog.blog-single .top-img .icon-area {
        right: 20px;
        width: 60px;
        height: 60px;
    }
    #single-blog .single-step h1 {
        font-size: 24px;
        line-height: 34px;
    }
    #single-blog .quote-step {
        padding: 20px 5px;
    }
    #single-blog .about-me {
        padding: 30px 0;
    }
    #latest-articles.blog-single .section-header .title {
        font-size: 30px;
        line-height: 40px;
    }
    #latest-articles .section-header a {
        width: 80px;
    }
    #contact-me .offset-1{
        margin-left: 0;
    }
}

@media (max-width: 320px) {
    #my-story-section .right-area .user {
        transform: translate(-79px, 60px);
    }
    #testimonials .slick-active .left-area img {
        transform: translate(-15px, -45px);
    }
}

/* Student Say Section */
.outer-main-div{
    background-color: rgb(172, 166, 166);
    height: auto;
    width: auto;
    border: 2px solid red;
}
#outer-main-id{
    margin-left: 15%;
    margin-right: 15%;
    padding: 2rem;
}

#student-img{
    border:"2px solid blue";
}


#scrollToTop {
    position: fixed;
    width: 45px;
    right:30px;
    bottom: 80px;
    height: 45px;
    font-size: 2rem;
    z-index: 9;
    background-image: linear-gradient(45deg, rgb(247,112,98) 0%, rgb(254,81,150) 100%);
    border-radius: 5px;
    color: #fff;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
    transition: all 1s;
    transform: translateY(100%);
    /* transition: 0.8s ease-in-out;
    -webkit-transition:  1s; */
}
.contact_input:focus{
    border: 2px solid rgb(247,112,98)!important;
}

/*17/5/2023 -shuvo */
.appbar_link li {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    padding: 0px;
    font-weight: 400;
    font-family: var(--body-font);
    font-size: 18px;
    text-transform: none;

}
.appbar_link li:hover{
    background: none;
}

.grid__link_item{
    padding: 0 !important;
    line-height: 24px;
}

.grid__link > .item{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.nav__box::before{
    content: "";
    background-color: "#F23460red";
}

.custom-dropdown-toggle {
    background-color: #F23460;
    position: relative;
  }
  
  .custom-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateY(12px); 
    padding: 0;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .triangle {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #F23460; 
  }

.dropdown__services__header{
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* identical to box height */
color: #000000;
padding: .5rem 1rem;

}

.left__grid_1  li{
    padding: .3rem 0;
    
}
.right__grid_1 li{
    padding: .3rem 0;

}

.qa_services_left a, .qa_services_right a{
font-family: 'Poppins';
font-style: normal;
font-weight: 500 !important;
font-size: 16px !important;
line-height: 24px;
}

.qa_services_left a{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}


.qa_services_left li, .qa_services_right li {
    display: flex;
    justify-content: start;
    align-items: center;

}

.tab__header__name{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
}
#c_d_m{
    left: -340%;
}

#header-section .navbar .navbar-nav li a.active, #header-section .navbar .navbar-nav .grid__link li a:hover{
background-image: none;
}
@media only screen and (max-width: 991px) and (min-width: 768px)  {
    .navbar-collapsed{
        display: table;
    }
    
}

@media only screen and (max-width: 600px){
    .qa_services_right{
        margin-right: 50px;
    }
    .qa_2{
        margin-right: 40px;

    }
}
@media only screen and (max-width: 991px){
    #c_d_m{
        left: -230%;
    }
}


#header-section .qa_services_left > .grid__link_item:hover {
    border: 1px solid;
    background-image: none;
    border-radius: 0;
}